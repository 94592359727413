import config from "./config";
import { ERROR, LOG, WARN } from "../constants";
import { IconColor, Meeting } from "../types";

export type error = typeof ERROR;
export type log = typeof LOG;
export type warn = typeof WARN;

export type LoggerBody = {
  type: error | log | warn;
  message: any;
};

/** Ends browser full screen if the browser is in full screen mode */
export const endFullScreen = (): void => {
  // exit full screen
  if (document?.fullscreenElement !== null) {
    document?.exitFullscreen();
  }
};

/** Helper function that gets the meeting id from a url path
 * @param {string} path - Pathname of a url
 * @param {boolean} replayMeetingURL - use the replay path logic
 * @returns {string} - Meeting ID derived from the path
 */
export const populateMeetingIdFromURL = (
  path: string,
  replayMeetingURL?: boolean
): string => {
  let getLastItem;
  if (replayMeetingURL) {
    getLastItem = (thePath: string) =>
      thePath.substring(
        getNthIndexOfChar(thePath, "/", 2) + 1,
        path.lastIndexOf("/replay")
      );
  } else if (!path.endsWith("/")) {
    getLastItem = (thePath: string) =>
      thePath.substring(thePath.lastIndexOf("/") + 1);
  } else {
    getLastItem = (thePath: string) =>
      thePath.substring(
        getNthIndexOfChar(thePath, "/", 2) + 1,
        thePath.lastIndexOf("/")
      );
  }
  let meetingPath = getLastItem(path);
  if (
    meetingPath != "join" &&
    meetingPath != "meeting" &&
    meetingPath != "demo"
  ) {
    return meetingPath;
  } else return "";
};

/**
 * @param {URLSearchParams} urlParams - Params passed from URL type
 * @returns {{string, string}} - returns an object with a firstName and lastName
 */
export const findNameFromParams = (urlParams: URLSearchParams) => {
  const firstName = urlParams.get("firstName");
  const lastName = urlParams.get("lastName");
  return { firstName, lastName };
};

/** Helper function to get a substring at its nth occurrence within a string
 * @param {string} inputString - string you want to operate on
 * @param {string} subString - string you want to find
 * @param {number} n - the count of times you want to find i.e. 3 would skip the first two times the substring is found
 * @returns {number} - index of the desired substring
 */
export function getNthIndexOfChar(
  inputString: string,
  subString: string,
  n: number
): number {
  let count = 0;
  let currentIndex = -1;

  while (count < n) {
    currentIndex = inputString.indexOf(subString, currentIndex + 1);

    if (currentIndex === -1) {
      break; // Character not found
    }

    count++;
  }

  return currentIndex;
}

/** Helper function that consoles only in lower environments
 * @param {any} message - Content to log
 */
export const consoleNonProd = (...message) => {
  if (config.env.toLowerCase() !== "production") {
    // eslint-disable-next-line no-console
    console.groupCollapsed(...message);
    // eslint-disable-next-line no-console
    console.trace();
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
};

/** Logs client side messages to AWS CloudWatch
 * @param {LoggerBody} body - Object that contains the log level and message to log
 */
export const logger = (body: LoggerBody) => {
  fetch(config.loggerUrl, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
    mode: "no-cors",
  });
};

/** Determines whether or not class session editing should be disabled
 * @param {Meeting["meetingTime"]} inputClassTime
 * @returns {boolean}
 */
export const shouldDisableClass = (
  inputClassTime: Meeting["meetingTime"]
): boolean => {
  if (inputClassTime && inputClassTime <= new Date().getTime()) {
    return true;
  } else {
    return false;
  }
};

/**
 * Download a file on client.
 * @param filename file name of download.
 * @param text string to download.
 */
export const download = (filename: string, text: string) => {
  // Create a tag w/ encoded text.
  const a = document.createElement("a");
  a.setAttribute(
    "href",
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  a.setAttribute("download", filename);

  // Hide a tag
  a.style.display = "none";

  // Append a tag
  document.body.appendChild(a);

  // Click, triggering download.
  a.click();

  // Remove a tag.
  document.body.removeChild(a);
};

/**
 *
 */
export const allowReplay = (
  meetingId: string,
  allowedMeetings: string[]
): boolean => {
  return allowedMeetings.some((allowedMeeting) =>
    meetingId.includes(allowedMeeting)
  );
};

/** Converts color code as required for GQL into hex code that is usable in css/html */
export const getHexFromIconColor = (color: IconColor): string => {
  return color.replace("_", "#");
};
