import React, {
  useEffect,
  useState,
  Suspense,
  MutableRefObject,
  useCallback,
  useMemo,
} from "react";
import {
  useLocalVideo,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import { isMobile, isTablet } from "react-device-detect";
import { Attendee, Board, BoardType } from "../../types";
import Spinner from "../spinner";
import { Modal } from "../modal/modal";
import { Instructions } from "../instructions";
import { CanvasDataEvent, EventPayload } from "../../providers/types";
import { useAppContext } from "../../contexts/appContext";
import {
  ActionType,
  AttendeeState,
  CanvasMode,
  MeetingRole,
  State,
} from "../../contexts/types";
import classnames from "classnames";
import { BoardViewer } from "./boardViewer";
import { LaserPointer } from "./laserpointer";
import { StorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import { Devices } from "../devices";
import { fetchAll } from "../../utils/cacheMachine";
import Player from "video.js/dist/types/player";
import { useCanvas } from "../../hooks/useCanvas";
import { ChatReplayMessage } from "../../providers/replayDataMessages";
import { ChatDataMessage } from "../../providers/dataMessages";
import { CaptionType } from "../../providers/dataCaptions";
import { consoleNonProd } from "../../utils/utilityBeltUtils";
import { useDataEventHandler } from "../../hooks/useDataEventHandler";
import { VideoReplayTimeAndState } from "../replay/replayTypes";
import { EditCanvasTools } from "./editCanvasTools";
import { PresentationCanvasTools } from "./presentationCanvasTools";
import { ReplayCanvasTools } from "./replayCanvasTools";
import { ViewOnlyCanvasTools } from "./viewOnlyCanvasTools";
import { useScreenContext } from "../../contexts/screenContext";

type CanvasProps = {
  handleRefetchBoards: (newSelectedBoardNumber?: number) => void;
  sendEvent?:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
  showInstructors?: boolean;
  setShowInstructors?: (show: boolean) => void;
  toggleInstructors?: () => void;
  events?: CanvasDataEvent[];
  mode: CanvasMode;
  playerRef?: MutableRefObject<Player>;
  useReplayChat?: boolean;
  useReplayCaptions?: boolean;
  chapters?: VTTCue[];
  replayCaptions?: CaptionType[];
  activeChapter?: number;
  captions?: CaptionType[];
  currentPartial?: CaptionType;
  replayExplore?: boolean;
  messages?: ChatReplayMessage[] | ChatDataMessage[];
  messageCount?: number;
  sendMessage?: (message: string, attendee?: AttendeeState) => void;
  handleSelectChapter?: (timestamp: number, chapter: number) => void;
  maxTimeMs?: number;
  showChapterList?: boolean;
  setShowChapterList?: (show: boolean) => void;
  showEditTools?: boolean;
};

/** Component containing the Canvas board components, instructor slide manager, and student toolbar
 * @param {CanvasProps} props
 * @prop {() => void} handleRefetchBoards - Function that refetches the boards for the current meeting
 * @prop {((eventPayload: EventPayload) => void) | undefined} sendEvent - Function to send events using the DataEventsProvider
 * @prop {CanvasDataEvent[]} events - List of app events from the DataEventsProvider, defaults to an empty array
 * @prop {CanvasMode} mode - Display mode for the canvas
 * @prop {Player} playerRef - Ref object containing video player for replay mode
 * @prop {boolean} useReplayChat - Whether or not the meeting replay has a chat
 * @prop {messages[]} ChatDataMessage[] or ChatReplayMessage[] - List of messages DataMessages provider
 * @prop {VTTCue[]} chapters - pass through from replay down to the video controls / progress bar
 * @prop {CaptionType[]} captions - pass through from Meeting down to the closed captions
 * @prop {CaptionType} currentPartial - pass through from Meeting down to the closed captions
 * @prop {boolean} replayExplore - boolean for replay explore
 * @prop {() => Promise<void>} sendMessage - Optional function that is called when you send a message
 * @prop {boolean} showEditTools - Whether or not to render edit tools. Defaults to true
 */
export default function Canvas({
  handleRefetchBoards,
  sendEvent,
  showInstructors,
  setShowInstructors,
  toggleInstructors,
  events = [],
  mode,
  playerRef,
  replayExplore,
  useReplayChat,
  useReplayCaptions,
  replayCaptions,
  captions,
  currentPartial,
  chapters,
  activeChapter,
  messages,
  messageCount,
  sendMessage,
  showChapterList,
  setShowChapterList,
  handleSelectChapter,
  maxTimeMs,
  showEditTools = true,
}: CanvasProps) {
  const {
    handleSelectSlide,
    scrollToBoard,
    focusOnBoard,
    handleChangeSlide,
    selectedBoard,
    setSelectedBoard,
    handleSetSelectedBoard,
  } = useCanvas({ sendEvent, events });
  const { getActionForDataEvent } = useDataEventHandler();
  const { meetingRole, simpleView, isInstructorOrModerator, state, dispatch } =
    useAppContext();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [leader] = useState(
    mode !== CanvasMode.PRESENTATION || meetingRole === MeetingRole.Presenter
  );
  const { getLocalStorage } = useLocalStorage();
  const locAttendee: Attendee = getLocalStorage(StorageKeys.attendee);
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();

  // TODO: see if this Canvas state should be refactored into its own canvas UI state
  const [showInstructions, setShowInstructions] = useState(
    meetingRole === MeetingRole.Audience
  );
  const [showClosedCaptions, setShowClosedCaptions] = useState(false);
  const [editBoardIIIF, setEditBoardIIIF] = useState(false);
  const [iiifRemoved, setIIIFRemoved] = useState(false);
  const [showDeviceSettings, setShowDeviceSettings] = useState(false);
  const toggleInstructions = useCallback(
    () => setShowInstructions(!showInstructions),
    [showInstructions]
  );
  const toggleClosedCaptions = useCallback(
    () => setShowClosedCaptions(!showClosedCaptions),
    [showClosedCaptions]
  );
  const toggleDeviceSettings = useCallback(
    () => setShowDeviceSettings(!showDeviceSettings),
    [showDeviceSettings]
  );

  // Props shared across the Presentation, Edit, and View Only toolbars relating to boards/slides
  const toolsBoardProps = useMemo(
    () => ({
      editBoardIIIF,
      focusOnBoard,
      handleChangeSlide,
      handleRefetchBoards,
      handleSelectSlide,
      handleSetSelectedBoard,
      iiifRemoved,
      scrollToBoard,
      setEditBoardIIIF,
      setIIIFRemoved,
    }),
    [
      editBoardIIIF,
      focusOnBoard,
      handleChangeSlide,
      handleRefetchBoards,
      handleSelectSlide,
      handleSetSelectedBoard,
      iiifRemoved,
      scrollToBoard,
      setEditBoardIIIF,
      setIIIFRemoved,
    ]
  );

  // Props shared across the Presentation and Replay toolbars relating to content display
  const toolsDisplayProps = useMemo(
    () => ({
      currentPartial,
      events,
      messageCount,
      setShowInstructors,
      showClosedCaptions,
      showInstructors,
      toggleClosedCaptions,
      toggleInstructions,
      toggleInstructors,
    }),
    [
      currentPartial,
      events,
      messageCount,
      setShowInstructors,
      showClosedCaptions,
      showInstructors,
      toggleClosedCaptions,
      toggleInstructions,
      toggleInstructors,
    ]
  );

  const [sharedReplayVideoData, setSharedReplayVideoData] =
    useState<VideoReplayTimeAndState>({
      time: 0,
      isPlaying: false,
      volume: 0,
      speed: 1,
    });

  const updateSharedData = useCallback((value) => {
    setSharedReplayVideoData(value);
  }, []);

  useEffect(() => {
    document?.documentElement?.style.setProperty(
      "--closed-caption-size",
      `larger`
    );
  }, []);

  /**
   * if for whatever reason the index doesn't load the service worker try again
   * when the canvas loads
   */
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/serviceWorker.js")
          .then((registration) => {
            consoleNonProd("Service worker registered!", registration);
          })
          .catch((error) => {
            consoleNonProd("Error registering service worker:", error);
          });
      });
    }
  }, []);

  /** Syncs user with presentation on component load */
  useEffect(() => {
    if (!firstLoad) return;
    // checks on initial load for the latest selected board if you join late. Otherwise ignore
    if (events?.length) {
      const eventObj = events[events.length - 1];
      if (eventObj.event) {
        const event: EventPayload = JSON.parse(eventObj.event);
        if (event.leader) {
          handleSelectSlide(event.currentSlideNumber);
          dispatch({
            type: ActionType.HANDLE_CANVAS_INIT_LOAD,
            payload: {
              exploreMode: event.exploreMode,
              spotlightInstructor: event.spotlightInstructor,
            },
          });
        }
      }
    } else {
      // Default to selecting the first board if the current board is not set in state
      if (!state.currentBoard) {
        handleSelectSlide(1);
      }
    }

    setFirstLoad(false);
    /** Ignoring this error because we only want this useEffect to fire once on page load */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Set selected board when it updates */
  useEffect(() => {
    if (state.currentBoard?.sortOrder) {
      const board: Board = state.slides?.find(
        (board) => board.sortOrder === state.currentBoard?.sortOrder
      );
      if (state.slides) {
        const nextBoard = state.slides[board?.sortOrder];
        if (nextBoard?.type === BoardType.THREEDEE) {
          setTimeout(() => {
            fetchAll([nextBoard.boardThreedee.sourceUrl]);
          }, 500);
        }
        if (nextBoard?.type === BoardType.PANORAMA) {
          setTimeout(() => {
            fetchAll([
              nextBoard.boardPanorama.panorama.back,
              nextBoard.boardPanorama.panorama.bottom,
              nextBoard.boardPanorama.panorama.front,
              nextBoard.boardPanorama.panorama.left,
              nextBoard.boardPanorama.panorama.right,
              nextBoard.boardPanorama.panorama.top,
            ]);
          }, 500);
        }
      }
      board && setSelectedBoard(board);
    }
  }, [state.slides, state.currentBoard, dispatch]);

  /** Set cursor style when laser toggles on/off */
  useEffect(() => {
    let iiifView = document.getElementById("iiif-Viewer");
    if (
      state.showLaser === true &&
      state.laserInstructor === locAttendee?.AttendeeId &&
      iiifView
    ) {
      iiifView.style.cursor = "none";
    } else if (state.showLaser === false && iiifView) {
      iiifView.style.cursor = "grab";
    }
  }, [state.showLaser, state.laserInstructor, locAttendee?.AttendeeId]);

  /** Ingests canvas events and updates state accordingly */
  useEffect(() => {
    consoleNonProd(
      "canvas event handler",
      events?.length && JSON.parse(events[events.length - 1]?.event)
    );

    if (events?.length) {
      const eventObj = events[events.length - 1];

      if (!eventObj.isSelf && eventObj.event) {
        const event: EventPayload = JSON.parse(eventObj.event);

        const action = getActionForDataEvent(
          event,
          isVideoEnabled,
          muted,
          setShowInstructors,
          handleSelectSlide,
          toggleMute,
          toggleVideo
        );

        // If there are values in the payload, dispatch the AppContext state update
        if (Object.keys(action.payload).length) {
          dispatch(action);
        }
      }
    }
    /** Ignoring the error in the dependency array because including the setters was causing a re-render loops and they
     * do not need to be included since the setters should not be changing once they are created
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  // during a class or anything witht the canvas we want the screen to be the limits
  // of the vxp
  useEffect(() => {
    if (document?.body) {
      document.body.style.overflow = "hidden";
    }
    return function () {
      if (document?.body) {
        document.body.style.overflow = "initial";
      }
    };
  }, []);

  return (
    <main id="main-canvas">
      <div
        className={classnames("canvas", {
          "canvas__mobile-full-min-height": isMobile || isTablet,
        })}
        id="main-canvas"
      >
        <BoardViewer
          board={selectedBoard}
          events={events}
          sendEvent={sendEvent}
          leader={leader}
          handleRefetchBoards={handleRefetchBoards}
          mode={mode}
          editBoardIIIF={editBoardIIIF}
          setIIIFRemoved={setIIIFRemoved}
          replayVideoState={sharedReplayVideoData}
        />

        <Suspense fallback={<Spinner />}>
          {mode === CanvasMode.EDIT && (
            <EditCanvasTools
              {...toolsBoardProps}
              showEditTools={showEditTools}
            />
          )}
          {mode === CanvasMode.PRESENTATION && (
            <PresentationCanvasTools
              {...toolsBoardProps}
              {...toolsDisplayProps}
              captions={captions}
              messages={messages as ChatDataMessage[]}
              sendEvent={sendEvent}
              sendMessage={sendMessage}
              showDeviceSettings={showDeviceSettings}
              toggleDeviceSettings={toggleDeviceSettings}
              useInstructorTools={
                meetingRole === MeetingRole.Presenter || isInstructorOrModerator
              }
            />
          )}
          {mode === CanvasMode.REPLAY && (
            <ReplayCanvasTools
              {...toolsDisplayProps}
              activeChapter={activeChapter}
              chapters={chapters}
              handleSelectChapter={handleSelectChapter}
              maxTimeMs={maxTimeMs}
              messages={messages as ChatReplayMessage[]}
              playerRef={playerRef}
              replayCaptions={replayCaptions}
              replayExplore={replayExplore}
              setShowChapterList={setShowChapterList}
              setShowInstructions={setShowInstructions}
              showChapterList={showChapterList}
              showInstructions={showInstructions}
              showInstructors={showInstructors}
              toggleClosedCaptions={toggleClosedCaptions}
              updateSharedData={updateSharedData}
              useReplayCaptions={useReplayCaptions}
              useReplayChat={useReplayChat}
            />
          )}
          {mode === CanvasMode.VIEW_ONLY && (
            <ViewOnlyCanvasTools
              {...toolsBoardProps}
              showEditTools={showEditTools}
            />
          )}
        </Suspense>
      </div>
      {/* Laser pointer */}
      {state.showLaser && (
        <LaserPointer localAttendee={locAttendee} events={events} />
      )}

      {/* Devices modal */}
      {mode === CanvasMode.PRESENTATION && (
        <Modal
          dismissible={true}
          display={showDeviceSettings}
          onDismiss={toggleDeviceSettings}
        >
          <div className="__modal-content">
            <Devices toggleDeviceSettings={toggleDeviceSettings} />
          </div>
        </Modal>
      )}

      {/* Instructions Modal -- visible in presentation and replay mode */}
      <Modal
        dismissible={true}
        display={
          mode !== CanvasMode.EDIT &&
          mode !== CanvasMode.VIEW_ONLY &&
          showInstructions &&
          !simpleView
        }
        onDismiss={toggleInstructions}
      >
        <div className="__modal-content">
          <Instructions
            handleContinue={toggleInstructions}
            isReplay={mode === CanvasMode.REPLAY}
          />
        </div>
      </Modal>
    </main>
  );
}
