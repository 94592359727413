import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useAppContext } from "../../../contexts/appContext";
import {
  ATTENDEE_LEAVE_MEETING,
  END_MEETING,
} from "../../../graphql/mutations";
import { SERVICE_CACHE, routes } from "../../../constants";
import { StorageKeys, useLocalStorage } from "../../../hooks/useLocalStorage";
import { PopUp } from "../../popUps/popUp";
import { Modal } from "../../modal/modal";
import { consoleNonProd, endFullScreen } from "../../../utils/utilityBeltUtils";
import { useFeatureContext } from "../../../contexts/featureContext";
import { EndMeetingInput, FeatureFlagType } from "../../../types";
import { ActionType } from "../../../contexts/types";
import { useDataMessages } from "../../../providers/dataMessages";

/** Confirmation modal that appears when user clicks "Leave" meeting button
 * @param {EndMeetingControlProps} props
 * @prop {boolean} showEndModal - Whether or not the modal should be displayed
 * @prop {() => void} handleDismiss - Function to be called when modal is dismissed
 */
type EndMeetingControlProps = {
  showEndModal: boolean;
  handleDismiss: () => void;
};

export const EndMeetingControl: React.FC<EndMeetingControlProps> = ({
  showEndModal,
  handleDismiss,
}) => {
  const { featureFlags } = useFeatureContext();
  const recordingPrompt = featureFlags.has(FeatureFlagType.RECORDING_PROMPT);
  const recordingSetPrevious = featureFlags.has(
    FeatureFlagType.RECORDING_SET_PREVIOUS
  );
  const { isInstructorOrModerator, isDemo, state } = useAppContext();
  const [showRecordingModal, setShowRecordingModal] = useState(false);

  const { joinInfo, dispatch } = useAppContext();
  const { resetMessages } = useDataMessages();
  const navigate = useNavigate();
  const { clearLocalStorage, getLocalStorage } = useLocalStorage();
  const meeting = getLocalStorage(StorageKeys.meeting);

  const [endMeeting, { error }] = useMutation(END_MEETING);
  const [attendeeLeaveMeeting] = useMutation(ATTENDEE_LEAVE_MEETING);

  const allowEndForAll = isInstructorOrModerator || isDemo;
  const popUpMessage = allowEndForAll
    ? `${
        isInstructorOrModerator
          ? "As an administrator of this meeting"
          : "In a demo meeting"
      }, you have the option to end the meeting for all participants. Please confirm what you want to do.`
    : "Are you sure you want to leave the meeting?";

  const recordingMessage = (
    <Fragment>
      <p>
        Select “Yes” to begin processing the recording from this class. Once the
        processing is complete the replay will be available to watch using the
        class link.
      </p>
      {recordingSetPrevious && (
        <p>
          You will also be able to select or re-assign the recording for the
          class replay at any time on the course information page.
        </p>
      )}
      <p></p>
    </Fragment>
  );

  const leaveMeeting = async (): Promise<void> => {
    attendeeLeaveMeeting(getLocalStorage(StorageKeys.attendee)?.id);
    clearLocalStorage();
    // clear out the service worker cache on leave
    caches.delete(SERVICE_CACHE);
    // exit full screen
    endFullScreen();
    dispatch({
      type: ActionType.SET_INIT_STATE,
    });
    navigate("/" + routes.join);
  };

  const handleEndAndNav = async (path: string, setReplayRecording = false) => {
    const input: EndMeetingInput = {
      id: joinInfo.Meeting.id,
      setReplayRecording,
    };

    await endMeeting({
      variables: { input },
    });
    dispatch({
      type: ActionType.SET_INIT_STATE,
    });
    navigate(path);
  };

  const endMeetingForAll = async (): Promise<void> => {
    clearLocalStorage();
    // clear out the service worker cache on meeting end
    caches.delete(SERVICE_CACHE);
    // exit full screen
    endFullScreen();

    // show the recording modal if the meeting is currently being recorded
    if (recordingPrompt && meeting?.currentlyRecording) {
      setShowRecordingModal(true);
    } else {
      handleEndAndNav(
        "/" + `${isInstructorOrModerator ? routes.admin : routes.join}`
      );
    }
  };

  useEffect(() => {
    error && consoleNonProd(error);
  }, [error]);

  return (
    <Fragment>
      {/* Leave modal */}
      <Modal
        dismissible={true}
        display={showEndModal}
        onDismiss={handleDismiss}
      >
        <div className="__modal-content">
          <PopUp
            userMessage={popUpMessage}
            popUpHeader="Leave Meeting"
            buttonText1="Leave Meeting"
            buttonText2={allowEndForAll ? "End Meeting for All" : "Cancel"}
            onClick1={leaveMeeting}
            onClick2={allowEndForAll ? endMeetingForAll : handleDismiss}
          />
        </div>
      </Modal>

      {/* Recording modal */}
      <Modal dismissible={false} display={showRecordingModal}>
        <div className="__modal-content">
          <PopUp
            popUpHeader="Would you like to use the recording from this class for async replay?"
            userMessage={recordingMessage}
            buttonText1="No"
            buttonText2="Yes"
            onClick1={() => handleEndAndNav("/" + routes.join)}
            onClick2={() => handleEndAndNav("/" + routes.admin, true)}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
