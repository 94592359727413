import React, { Fragment, Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { adminRoutes, routes } from "../constants";
import { useAuthContext } from "../contexts/authContext";
import { Admin } from "../pages/admin";
import { Course } from "../pages/admin/course";
import { CreateCourse } from "../pages/admin/createCourse";
import { LogIn } from "../pages/admin/logIn";
import { EditClass } from "../pages/admin/editClass";
import { Demo } from "../pages/admin/demo";
import { Join } from "../pages/join";
import { Meeting } from "../pages/meeting";
import { StyleGuide } from "../pages/styleGuide";
import { ProtectedRoute } from "./protectedRoutes";
import { FeatureFlag, FeatureFlagType, Query } from "../types";
import { useFeatureContext } from "../contexts/featureContext";
import { BackDoor } from "../pages/admin/backDoorLogin";
import { ViewClass } from "../pages/admin/viewClass";
import config from "../utils/config";
import { useLazyQuery } from "@apollo/client";
import { ON_APP_LOAD } from "../graphql/queries";
import { useAppContext } from "../contexts/appContext";
import { FeatureFlagRoute } from "./featureFlagRoutes";
import { Watch } from "../pages/watch";
import { ViewPresentation } from "../pages/viewPresentation";

export const AppRoutes: React.FC = () => {
  const { handleLogout, setIsAuthenticated } = useAuthContext();
  const { featureFlags, setFeatureFlags } = useFeatureContext();
  const { setFirstName, setLastName } = useAppContext();
  const currentUrl = new URL(document.URL);
  const urlParams = new URLSearchParams(currentUrl.searchParams);

  const [onAppLoad, { loading }] = useLazyQuery(ON_APP_LOAD, {
    onCompleted: ({
      getAuthenticatedUser,
      getAllActiveFeatureFlags,
    }: Pick<Query, "getAuthenticatedUser" | "getAllActiveFeatureFlags">) => {
      if (getAllActiveFeatureFlags) {
        let tempSet: Set<FeatureFlagType> = new Set<FeatureFlagType>();
        getAllActiveFeatureFlags?.forEach((element: FeatureFlag) => {
          tempSet.add(element.name as FeatureFlagType);
        });

        setFeatureFlags(tempSet);
      }

      if (getAuthenticatedUser) {
        setIsAuthenticated(getAuthenticatedUser.isAuthenticated);
        setFirstName(getAuthenticatedUser.firstName);
        setLastName(getAuthenticatedUser.lastName);
      } else {
        handleLogout();
      }
    },
  });

  /** Get the auth user and feature flag info on app load */
  useEffect(() => {
    // If there are no feature flags, that means we have not run the on app load query yet
    if (featureFlags.size === 0) {
      onAppLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags]);

  return loading ? (
    <Suspense />
  ) : (
    <Routes>
      {/* Join class */}
      <Route path={routes.join} element={<Join />} />
      <Route path={`${routes.join}/:meetingId`} element={<Join />} />
      {/* TODO: Add in no meeting redirect */}
      <Route path={`${routes.meeting}/:meetingId`} element={<Meeting />} />
      <Route path={`${routes.watch}/:meetingId`} element={<Watch />} />
      <Route
        path={`${routes.viewPresentation}/:meetingId`}
        element={<ViewPresentation />}
      />
      {/* Admin Routes */}
      <Route path={routes.admin}>
        <Route path={adminRoutes.logIn} element={<LogIn />} />
        <Route
          path={adminRoutes.demo}
          element={
            <ProtectedRoute redirect={`/${routes.admin}/${adminRoutes.logIn}`}>
              <Demo />
            </ProtectedRoute>
          }
        />
        <Route
          path={adminRoutes.createCourse}
          element={
            <ProtectedRoute redirect={`/${routes.admin}/${adminRoutes.logIn}`}>
              <CreateCourse />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${adminRoutes.course}/:courseSlug`}
          element={
            <ProtectedRoute redirect={`/${routes.admin}/${adminRoutes.logIn}`}>
              <Course />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${adminRoutes.meetingPresentationEditor}/:meetingId`}
          element={
            <ProtectedRoute redirect={`/${routes.admin}/${adminRoutes.logIn}`}>
              <EditClass />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${adminRoutes.meetingPresentationViewer}/:meetingId`}
          element={
            <ProtectedRoute redirect={`/${routes.admin}/${adminRoutes.logIn}`}>
              <ViewClass />
            </ProtectedRoute>
          }
        />
        <Route
          path=""
          element={
            <ProtectedRoute redirect={`/${routes.admin}/${adminRoutes.logIn}`}>
              <Admin />
            </ProtectedRoute>
          }
        />
        {/* Redirect to /admin for all unknown admin routes */}
        <Route
          path="*"
          element={<Navigate to={`/${routes.admin}`} replace={true} />}
        />
      </Route>

      {/* Routes that are under the DEMO feature flag */}
      <Route
        path={`${adminRoutes.demo}`}
        element={
          <FeatureFlagRoute
            name={FeatureFlagType.DEMO}
            redirect={`/${routes.join}`}
          >
            <Demo />
          </FeatureFlagRoute>
        }
      />

      <Route
        path={`${adminRoutes.demo}/:meetingId`}
        element={
          <FeatureFlagRoute
            name={FeatureFlagType.DEMO}
            redirect={`/${routes.join}`}
          >
            <Demo />
          </FeatureFlagRoute>
        }
      />

      {/* Style Guide or other routes that should not be included in prod */}
      {config.env.toLowerCase() !== "production" && (
        <Fragment>
          <Route path={routes.styleGuide} element={<StyleGuide />} />
          <Route
            path={`${routes.admin}/${adminRoutes.backDoor}`}
            element={<BackDoor />}
          />
        </Fragment>
      )}

      {/* User research route that is only included in prod */}
      {config.env.toLowerCase() === "production" && (
        <Route
          path={`${routes.userResearch}`}
          element={
            <Navigate
              to={`/${routes.meeting}/0923lineinart-1694728800000/${
                urlParams ? "?" + urlParams : ""
              }`}
            />
          }
        />
      )}

      {/* Redirect to /join for all unknown routes */}
      <Route
        path="*"
        element={<Navigate to={`/${routes.join}`} replace={true} />}
      />
    </Routes>
  );
};
