import React, { Suspense } from "react";
import parseHtml from "html-react-parser";
import { CanvasDataEvent, EventPayload } from "../../providers/types";
import { Board, BoardType } from "../../types";
import Spinner from "../spinner";
import IIIFViewer from "./iiifViewer";
import { PanoramaViewer } from "./panoramaViewer";
import ThreeDeeViewer from "./threeDeeViewer";
import VideoViewer from "./videoViewer";
import { CanvasMode, State } from "../../contexts/types";
import { VideoReplayTimeAndState } from "../replay/replayTypes";

type BoardViewerType = {
  board: Board;
  events?: CanvasDataEvent[];
  sendEvent?:
    | ((eventPayload: EventPayload, appState: State) => void)
    | undefined;
  leader: boolean;
  handleRefetchBoards?: () => void;
  mode: CanvasMode;
  editBoardIIIF: boolean;
  setIIIFRemoved: (removed: boolean) => void;
  replayVideoState?: VideoReplayTimeAndState;
};

/** Component that contains the logic for rendering a board on the canvas
 * @param {BoardViewerType} props
 * @prop {Board} board - The board to be rendered
 * @prop {CanvasDataEvent[]} events - List of canvas events that record the leaders' actions
 * @prop {((eventPayload: EventPayload) => void) | undefined} sendEvent - Function to send a new canvas event
 * @prop {boolean} leader - Whether or not the current user has leadership permissions
 */
export const BoardViewer: React.FC<BoardViewerType> = ({
  board,
  events,
  sendEvent,
  handleRefetchBoards,
  leader,
  mode,
  editBoardIIIF,
  setIIIFRemoved,
  replayVideoState,
}) => {
  const selectedSourceURL = board && board.boardImages;

  return (
    <Suspense
      fallback={
        <div style={{ margin: "40vh auto" }}>
          <Spinner />
        </div>
      }
    >
      <div className="canvas__board-viewer" id="board-viewer">
        {/* IIIF Board */}
        {board && board.type === BoardType.IIIF && (
          <IIIFViewer
            tileSources={selectedSourceURL}
            leader={leader}
            events={events}
            handleRefetchBoards={handleRefetchBoards}
            mode={mode}
            editBoardIIIF={editBoardIIIF}
            setIIIFRemoved={setIIIFRemoved}
            sendEvent={sendEvent}
          />
        )}
        {/* Panorama Board */}
        {board && board.type === BoardType.PANORAMA && (
          <PanoramaViewer
            sourceUrl={board.boardPanorama?.panorama.sourceUrl}
            top={board.boardPanorama?.panorama.top}
            bottom={board.boardPanorama?.panorama.bottom}
            left={board.boardPanorama?.panorama.left}
            right={board.boardPanorama?.panorama.right}
            front={board.boardPanorama?.panorama.front}
            back={board.boardPanorama?.panorama.back}
            leader={leader}
            sendEvent={sendEvent}
            events={events}
            mode={mode}
          />
        )}
        {/* 3D Board */}
        {board &&
          board.type === BoardType.THREEDEE &&
          board?.boardThreedee?.sourceUrl && (
            <ThreeDeeViewer
              sourceUrl={board?.boardThreedee?.sourceUrl}
              leader={leader}
              scale={board?.boardThreedee?.scale}
              sendEvent={sendEvent}
              events={events}
              mode={mode}
            />
          )}
        {/* Video Board */}
        {board && board.type === BoardType.VIDEO && (
          <VideoViewer
            sourceUrl={board?.boardVideo?.sourceUrl}
            leader={leader}
            startTime={3}
            sendEvent={sendEvent}
            events={events}
            mode={mode}
            replayState={replayVideoState}
          />
        )}
        {/* Board Caption */}
        {board?.caption && (
          <div className="canvas__caption">{parseHtml(board.caption)}</div>
        )}
      </div>
    </Suspense>
  );
};
