import { useMutation } from "@apollo/client";
import React, { Fragment, useMemo, useState } from "react";
import { adminRoutes, routes } from "../../constants";
import {
  DELETE_MEETING,
  DUPLICATE_MEETING,
  START_AND_JOIN_MEETING,
  START_FHD_MEETING,
} from "../../graphql/mutations";
import {
  Course,
  CreateMeetingInput,
  DuplicateMeetingInput,
  GenerateVttStatus,
  Meeting,
  Mutation,
  MutationStartAndJoinMeetingArgs,
  MutationStartFhdMeetingArgs,
  ProcessRecordingStatus,
} from "../../types";
import { Button, PRIMARY, SECONDARY, SMALL, STANDARD } from "../buttons/button";
import { useJoinMeeting } from "../../hooks/useJoinMeeting";
import { Modal } from "../modal/modal";
import { PopUp } from "../popUps/popUp";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../buttons/iconButton";
import classnames from "classnames";
import { useFeatureContext } from "../../contexts/featureContext";
import { FeatureFlagType } from "../../types";
import Spinner from "../spinner";
import { useAppContext } from "../../contexts/appContext";
import { ActionType, MeetingMode } from "../../contexts/types";
import { StorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import { convertUtcToMonthDateTime } from "../../utils/dateUtils";
import { MoreActionsButton } from "../toolbars/controls/moreActions/moreActionsButton";
import { MoreActionsWrapper } from "../toolbars/controls/moreActions/moreActionsWrapper";
import { MoreActionsItem } from "../toolbars/controls/moreActions/moreActionsItem";
import { ModalHeader } from "../modal/modalHeader";
import { ModalFooter } from "../modal/modalFooter";
import { ModalBody } from "../modal/modalBody";
import { ClassForm } from "./classForm";
import { Icon } from "../icon";
import { useError } from "../../contexts/errorContext";
import {
  consoleNonProd,
  shouldDisableClass,
} from "../../utils/utilityBeltUtils";
import { getAttendeeDeviceDate } from "../../utils/deviceUtils";
import { RecordingDetailsModal } from "./recordingDetailsModal";
import variables from "../../styles/variables.scss";
import config from "../../utils/config";

type ClassSessionProps = {
  index: number;
  initMeeting: CreateMeetingInput | Meeting;
  handleUpdateMeeting: (index: number, meeting: CreateMeetingInput) => void;
  disabled: boolean;
  createMode: boolean;
  autoFocus: boolean;
  handleSetCourseInfo: (course: Course) => void;
  handleSetMeetingInfo: (meeting: Meeting) => void;
  handleSubmitUpdateCourse: () => void;
};

export const ClassSession: React.FC<ClassSessionProps> = ({
  index,
  initMeeting,
  handleUpdateMeeting,
  disabled,
  createMode,
  autoFocus,
  handleSetCourseInfo,
  handleSetMeetingInfo,
  handleSubmitUpdateCourse,
}) => {
  const newClass = !initMeeting?.id;
  const sessionSimpleView =
    !initMeeting.instructorAV && !initMeeting.studentAV && !initMeeting.chat;
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMoreActions, setShowMoreActions] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDuplicateForm, setShowDuplicateForm] = useState(false);
  const [showRecordingInfo, setShowRecordingInfo] = useState(false);
  const [duplicateInput, setDuplicateInput] = useState<CreateMeetingInput>();
  const hasRecordings = useMemo(
    () => (initMeeting as Meeting).mediaPipelines?.length > 0,
    [initMeeting]
  );
  const replayRecording = useMemo(
    () =>
      (initMeeting as Meeting).mediaPipelines?.find((mp) => mp.replayRecording),
    [initMeeting]
  );
  const hasReplayRecording = useMemo(() => {
    return hasRecordings && !!replayRecording;
  }, [hasRecordings, replayRecording]);

  const replayRecordingStatus = useMemo(() => {
    let status = GenerateVttStatus.IN_PROGRESS;

    // Default to returning in progress if replayRecording is not set
    if (!replayRecording) return status;

    const generateStatuses = [
      replayRecording.generateEventsStatus,
      replayRecording.processRecordingStatus,
    ];

    // Add captions status only in the prod environment
    if (config.env.toLowerCase() === "production")
      generateStatuses.push(replayRecording.generateCaptionsStatus);

    // If any generate status is error, set status to error
    if (
      generateStatuses.includes[GenerateVttStatus.ERROR] ||
      generateStatuses.includes(GenerateVttStatus.ERROR_MISSING_FILES) ||
      generateStatuses.includes(ProcessRecordingStatus.ERROR) ||
      generateStatuses.includes(ProcessRecordingStatus.ERROR_TASK)
    ) {
      status = GenerateVttStatus.ERROR;
    }

    // If all generate statuses are success, have been manually updated, or have been replaced, set status to success
    if (
      generateStatuses.every(
        (s) =>
          s === GenerateVttStatus.SUCCESS ||
          s === GenerateVttStatus.MANUAL_UPDATE ||
          s === ProcessRecordingStatus.SUCCESS ||
          s === ProcessRecordingStatus.MANUAL_UPDATE ||
          s === ProcessRecordingStatus.REPLACED ||
          (s === GenerateVttStatus.ERROR && sessionSimpleView)
      )
    ) {
      status = GenerateVttStatus.SUCCESS;
    }

    return status;
  }, [replayRecording, initMeeting]);

  const { handleJoinMeeting } = useJoinMeeting();
  const navigate = useNavigate();
  const { setLocalStorage } = useLocalStorage();
  const { firstName, lastName, dispatch } = useAppContext();
  const { showError } = useError();
  const { featureFlags } = useFeatureContext();
  const moderatorFeature = featureFlags.has(FeatureFlagType.MODERATION);
  const fhdFeature = featureFlags.has(FeatureFlagType.FHD_RECORDING);

  const editOrViewIcon =
    createMode || disabled || shouldDisableClass(initMeeting?.meetingTime)
      ? ""
      : "edit-2";
  const editOrViewText =
    createMode || disabled || shouldDisableClass(initMeeting?.meetingTime)
      ? "View presentation"
      : "Edit presentation";
  const disableEditAndDelete =
    createMode || disabled || shouldDisableClass(initMeeting?.meetingTime);

  const [deleteMeetingMutation] = useMutation(DELETE_MEETING, {
    onCompleted({ deleteMeeting }: Pick<Mutation, "deleteMeeting">) {
      consoleNonProd(deleteMeeting);
      setDeletePopup(false);
      handleSetCourseInfo(deleteMeeting);
    },
    onError(error) {
      consoleNonProd(error);
    },
  });

  const [duplicateMeetingMutation] = useMutation(DUPLICATE_MEETING, {
    onCompleted({ duplicateMeeting }: Pick<Mutation, "duplicateMeeting">) {
      consoleNonProd(duplicateMeeting);
      setShowDuplicateForm(false);
      handleSetCourseInfo(duplicateMeeting);
    },
    onError(error) {
      consoleNonProd(error);
    },
  });

  const [startAndJoinMeetingMutation] = useMutation(START_AND_JOIN_MEETING, {
    async onCompleted({
      startAndJoinMeeting,
    }: Pick<Mutation, "startAndJoinMeeting">) {
      const { attendee, meeting, recordMeetingStatus, initVersionNumber } =
        startAndJoinMeeting;

      await handleJoinMeeting(attendee, meeting, MeetingMode.Attendee);
      setLocalStorage(StorageKeys.recordMeetingStatus, recordMeetingStatus);
      setLocalStorage(
        StorageKeys.initVersionNumber,
        initVersionNumber ? initVersionNumber : 1
      );
      dispatch({
        type: ActionType.UPDATE_PRESENTATION_VERSION,
        payload: {
          presentationVersion: initVersionNumber ? initVersionNumber : 1,
        },
      });
      setIsLoading(false);

      // Navigate to the meeting after the meeting manager starts
      // use location state here to pass meeting and attendee info to prevent issues w/ local storage clearing
      navigate(`/${routes.meeting}/${meeting.title}`, {
        state: {
          attendee,
          meeting,
          meetingMode: MeetingMode.Attendee,
          recordMeetingStatus,
          initVersionNumber,
        },
      });
    },
    onError(error) {
      consoleNonProd("Error starting meeting", error);
      setIsLoading(false);

      showError({
        details: error,
        error: "There was an issue starting and/or joining this meeting.",
      });
    },
  });

  const [startFHDMeetingMutation] = useMutation(START_FHD_MEETING, {
    async onCompleted({ startFhdMeeting }: Pick<Mutation, "startFhdMeeting">) {
      const { attendee, meeting, recordMeetingStatus, initVersionNumber } =
        startFhdMeeting;

      await handleJoinMeeting(attendee, meeting, MeetingMode.Attendee);
      setLocalStorage(StorageKeys.recordMeetingStatus, recordMeetingStatus);
      setLocalStorage(
        StorageKeys.initVersionNumber,
        initVersionNumber ? initVersionNumber : 1
      );
      setLocalStorage(StorageKeys.fhd, true);
      dispatch({
        type: ActionType.UPDATE_PRESENTATION_VERSION,
        payload: {
          presentationVersion: initVersionNumber ? initVersionNumber : 1,
        },
      });
      setIsLoading(false);

      // Navigate to the meeting after the meeting manager starts
      // use location state here to pass meeting and attendee info to prevent issues w/ local storage clearing
      navigate(`/${routes.meeting}/${meeting.title}`, {
        state: {
          attendee,
          meeting,
          meetingMode: MeetingMode.Attendee,
          recordMeetingStatus,
          initVersionNumber,
          fhd: true,
        },
      });
    },
    onError(error) {
      consoleNonProd("Error starting meeting", error);
      setIsLoading(false);

      showError({
        details: error,
        error: "There was an issue starting and/or joining this meeting.",
      });
    },
  });

  const deleteCurrentMeeting = (id: number) => {
    const input = {
      id: id,
      instructorAV: initMeeting.instructorAV,
      studentAV: initMeeting.studentAV,
      chat: initMeeting.chat,
      muteLock: initMeeting.muteLock,
      courseId: initMeeting.courseId,
    };
    deleteMeetingMutation({ variables: { input } });
  };

  const duplicateCurrentMeeting = () => {
    const input: DuplicateMeetingInput = {
      meetingId: initMeeting.id,
      meetingTime: duplicateInput?.meetingTime,
      muteLock: duplicateInput?.muteLock,
      videoOffLock: duplicateInput?.videoOffLock,
      instructorAV: duplicateInput?.instructorAV,
      studentAV: duplicateInput?.studentAV,
      chat: duplicateInput?.chat,
    };
    duplicateMeetingMutation({ variables: { input } });
  };

  const handleCopyMeetingLink = (e) => {
    // Copy the text inside the text field
    const route =
      hasReplayRecording && replayRecordingStatus === GenerateVttStatus.SUCCESS
        ? routes.watch
        : routes.meeting;
    navigator.clipboard.writeText(
      window.location.origin + "/" + route + "/" + initMeeting.title
    );
  };

  /** Copies link for viewing presentation to user clipboard */
  const handleCopyPresentationLink = (e) => {
    // Copy the text inside the text field
    const url =
      window.location.origin +
      "/" +
      routes.viewPresentation +
      "/" +
      initMeeting.title;
    navigator.clipboard.writeText(url);
  };

  const handleEditOrView = () => {
    dispatch({
      type: ActionType.SET_INIT_STATE,
    });
    shouldDisableClass(initMeeting?.meetingTime)
      ? navigate(
          `/${routes.admin}/${adminRoutes.meetingPresentationViewer}/${initMeeting.title}`
        )
      : navigate(
          `/${routes.admin}/${adminRoutes.meetingPresentationEditor}/${initMeeting.title}`
        );
  };

  const handleStartMeeting = (role?: number) => {
    setIsLoading(true);
    const variables: MutationStartAndJoinMeetingArgs = {
      id: (initMeeting as Meeting).id,
      input: {
        firstName,
        lastName,
        userRole: role,
        deviceInfo: getAttendeeDeviceDate(),
      },
    };
    startAndJoinMeetingMutation({ variables });
  };

  const handleStartFhdMeeting = () => {
    setIsLoading(true);
    const variables: MutationStartFhdMeetingArgs = {
      id: (initMeeting as Meeting).id,
      input: {
        firstName,
        lastName,
        userRole: 1,
        deviceInfo: getAttendeeDeviceDate(),
      },
    };
    startFHDMeetingMutation({ variables });
  };

  const toggleMoreActions = () => setShowMoreActions(!showMoreActions);

  const handleUpdateDuplicateInput = (
    _index: number,
    meeting: CreateMeetingInput
  ) => {
    setDuplicateInput(meeting);
  };

  return (
    <div className="class-session__wrapper">
      {!newClass && (
        <div className="class-session__title">
          <strong>Class Session {index + 1}</strong>:{" "}
          {convertUtcToMonthDateTime(initMeeting?.meetingTime)}
          {hasReplayRecording && (
            <div className="class-session__item">
              <Icon
                name={
                  replayRecordingStatus === GenerateVttStatus.SUCCESS
                    ? "film-success"
                    : replayRecordingStatus === GenerateVttStatus.ERROR
                    ? "film-alert"
                    : "film-loading"
                }
                toolTip="top"
                desc={
                  replayRecordingStatus === GenerateVttStatus.SUCCESS &&
                  sessionSimpleView
                    ? "There is no video for a simple view replay"
                    : replayRecordingStatus === GenerateVttStatus.SUCCESS
                    ? "Video has been successfully processed"
                    : replayRecordingStatus === GenerateVttStatus.ERROR &&
                      !sessionSimpleView
                    ? "There was an error while processing the video"
                    : `Video processing for this class is ${replayRecordingStatus
                        .toLocaleLowerCase()
                        .replace("_", " ")}`
                }
                className="class-session__simple-view"
                stroke={
                  replayRecordingStatus === GenerateVttStatus.ERROR &&
                  !sessionSimpleView
                    ? variables.actionRed
                    : replayRecordingStatus === GenerateVttStatus.SUCCESS
                    ? undefined
                    : variables.selectedGrey
                }
              />
            </div>
          )}
        </div>
      )}
      <div
        className={classnames("class-session", {
          "class-session-left-align": disabled,
        })}
      >
        {newClass ? (
          <ClassForm
            index={index}
            initMeeting={initMeeting}
            handleUpdateMeeting={handleUpdateMeeting}
            autoFocus={autoFocus}
            newClass={newClass}
          />
        ) : (
          <Fragment>
            <div className="class-session__item class-session__item--play-buttons">
              {!newClass &&
              hasReplayRecording &&
              (replayRecordingStatus === GenerateVttStatus.SUCCESS ||
                (replayRecordingStatus === GenerateVttStatus.ERROR &&
                  sessionSimpleView)) ? (
                <Button
                  icon="play"
                  onClick={() =>
                    navigate(`/${routes.watch}/${initMeeting.title}`)
                  }
                  btnType={PRIMARY}
                  hoverState={true}
                  size={SMALL}
                  id={"watch-meeting-button-" + index}
                  customWidth="200px"
                  disabled={createMode || disabled}
                >
                  Watch class recording
                </Button>
              ) : (
                <Fragment>
                  {moderatorFeature && (
                    <Button
                      onClick={() => handleStartMeeting(2)}
                      icon={isLoading ? "" : "play"}
                      btnType={PRIMARY}
                      hoverState={true}
                      size={SMALL}
                      disabled={
                        isLoading ||
                        createMode ||
                        disabled ||
                        hasReplayRecording
                      }
                      id={"start-meeting-moderator-button-" + index}
                      customWidth="200px"
                    >
                      {isLoading ? <Spinner /> : "Start / Join as moderator"}
                    </Button>
                  )}
                  <Button
                    onClick={() => handleStartMeeting(1)}
                    icon={isLoading ? "" : "play"}
                    btnType={PRIMARY}
                    hoverState={true}
                    size={SMALL}
                    disabled={
                      isLoading || createMode || disabled || hasReplayRecording
                    }
                    id={"start-meeting-inst-button-" + index}
                    customWidth="200px"
                  >
                    {isLoading ? <Spinner /> : "Start / Join as instructor"}
                  </Button>
                </Fragment>
              )}
            </div>

            <div className="class-session__item">
              <Button
                onClick={handleEditOrView}
                icon={editOrViewIcon}
                text={editOrViewText}
                btnType={SECONDARY}
                hoverState={true}
                size={SMALL}
                disabled={createMode || disabled}
                id={"admin-edit-presentation-button-" + index}
                customWidth="200px"
              />
              <Button
                onClick={() => setShowEditForm(true)}
                icon="adjustments-horizontal"
                text="Class settings"
                btnType={SECONDARY}
                hoverState={true}
                size={SMALL}
                id={"admin-class-settings-button-" + index}
                customWidth="200px"
              />
            </div>

            <div className="class-session__item --icons">
              <IconButton
                onClick={handleCopyMeetingLink}
                iconName="link"
                desc={`Copy ${
                  hasReplayRecording &&
                  replayRecordingStatus === GenerateVttStatus.SUCCESS
                    ? "view recording"
                    : "student join meeting"
                } link to clipboard`}
                toolTip="top"
                disabled={createMode || disabled}
                btnId={"copy-meeting-url-button-" + index}
              />
            </div>

            <div className="class-session__item">
              <MoreActionsButton
                toolTip="top"
                alignment="horizontal"
                showActionsInput={showMoreActions}
                id={`class-more-actions-${index}`}
                onClick={toggleMoreActions}
                disabled={createMode || disabled}
              >
                <MoreActionsWrapper
                  upOrDown="down"
                  leftOrRight="left"
                  itemClick={toggleMoreActions}
                >
                  <MoreActionsItem
                    text="Duplicate class"
                    id={`duplicate-class-${index}`}
                    icon="copy"
                    onClick={() => setShowDuplicateForm(true)}
                    disabled={false}
                  />
                  {fhdFeature && (
                    <MoreActionsItem
                      text="Record FHD content"
                      id={`record-class-${index}`}
                      icon="play"
                      onClick={handleStartFhdMeeting}
                      disabled={false}
                    />
                  )}
                  <MoreActionsItem
                    text='Copy "View only" presentation link'
                    onClick={handleCopyPresentationLink}
                    id={`copy-presentation-link-${index}`}
                    icon="link"
                    disabled={false}
                  />
                  {hasRecordings && (
                    <MoreActionsItem
                      text="View recording details"
                      id={`recording-info-${index}`}
                      icon="film"
                      onClick={() => setShowRecordingInfo(true)}
                      disabled={!hasRecordings}
                    />
                  )}
                  <MoreActionsItem
                    text="Delete class"
                    id={`delete-class-${index}`}
                    icon="trash"
                    onClick={(e) => setDeletePopup(true)}
                    disabled={disableEditAndDelete}
                  />
                </MoreActionsWrapper>
              </MoreActionsButton>
            </div>
          </Fragment>
        )}
      </div>

      {/* Delete meeting confirmation modal */}
      <Modal
        dismissible={true}
        display={deletePopup}
        onDismiss={() => setDeletePopup(false)}
      >
        <div className="__modal-content">
          <PopUp
            popUpHeader="Are you sure you want to delete this class?"
            buttonText1="Cancel"
            buttonText2="Yes"
            buttonType1="secondary"
            buttonType2="primary"
            onClick1={() => {
              setDeletePopup(false);
            }}
            onClick2={() => {
              deleteCurrentMeeting(initMeeting.id);
            }}
          />
        </div>
      </Modal>

      {/* Edit class details modal */}
      <Modal
        dismissible={true}
        display={showEditForm}
        onDismiss={() => setShowEditForm(false)}
      >
        <div className="pop-up class-session__pop-up">
          <ModalHeader>
            <h3 className="class-session__pop-up--header">
              Class Settings{" "}
              <span className="class-session__pop-up--subheader">
                Class Session {index + 1}
              </span>
            </h3>
          </ModalHeader>
          <ModalBody>
            <ClassForm
              index={index}
              initMeeting={initMeeting}
              handleUpdateMeeting={handleUpdateMeeting}
              autoFocus={false}
              className="class-session__edit-form"
            />
          </ModalBody>
          <ModalFooter>
            <div className="pop-up__buttons">
              <Button
                btnType={SECONDARY}
                disabled={false}
                size={STANDARD}
                text="Cancel"
                onClick={() => setShowEditForm(false)}
                id={"popup-footer-first-button-" + index}
              />
              <Button
                btnType={PRIMARY}
                disabled={
                  initMeeting.meetingTime < new Date().getTime() + 300000
                }
                size={STANDARD}
                text="Update class"
                onClick={handleSubmitUpdateCourse}
                id={"popup-footer-second-button-" + index}
              />
            </div>
          </ModalFooter>
        </div>
      </Modal>

      {/* Duplicate class details modal */}
      <Modal
        dismissible={true}
        display={showDuplicateForm}
        onDismiss={() => setShowDuplicateForm(false)}
      >
        <div className="pop-up">
          <ModalHeader>
            <h3>Enter date and time for duplicated class</h3>
          </ModalHeader>
          <ModalBody>
            <ClassForm
              index={index}
              initMeeting={initMeeting}
              handleUpdateMeeting={handleUpdateDuplicateInput}
              autoFocus={false}
              className="class-session__edit-form"
              duplicateClass={true}
            />
          </ModalBody>
          <ModalFooter>
            <div className="pop-up__buttons">
              <Button
                btnType={SECONDARY}
                disabled={false}
                size={STANDARD}
                text="Cancel"
                onClick={() => setShowDuplicateForm(false)}
                id={"popup-footer-first-button-" + index}
              />
              <Button
                btnType={PRIMARY}
                disabled={false}
                size={STANDARD}
                text="Duplicate class"
                onClick={duplicateCurrentMeeting}
                id={"popup-footer-second-button-" + index}
              />
            </div>
          </ModalFooter>
        </div>
      </Modal>

      {/* Recording details modal */}
      {showRecordingInfo && (
        <RecordingDetailsModal
          display={showRecordingInfo}
          onDismiss={() => setShowRecordingInfo(false)}
          meeting={initMeeting as Meeting}
          handleSetMeetingInfo={handleSetMeetingInfo}
          replayRecording={replayRecording}
        />
      )}
    </div>
  );
};
