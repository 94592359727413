import React from "react";
import { Navigate } from "react-router-dom";
import { FeatureFlagType } from "../types";
import { useFeatureContext } from "../contexts/featureContext";

type FeatureFlagRouteProps = {
  name: FeatureFlagType;
  redirect?: string;
  children: JSX.Element;
};

/** Wrapper component for routes that require an active feature flag
 * @param {FeatureFlagRouteProps} props
 * @prop {FeatureFlagType} name - Name of the feature flag that is required to render this route
 * @prop {string} redirect - The route to redirect when flag is not active
 * @prop {React.ReactNode} children - React component that will be rendered when flag is active
 */
export const FeatureFlagRoute: React.FC<FeatureFlagRouteProps> = ({
  name,
  redirect,
  children,
}) => {
  const { featureFlags } = useFeatureContext();
  const isActive = featureFlags.has(name);

  if (!isActive) {
    return <Navigate to={redirect ? redirect : "/"} replace={true} />;
  }

  return children;
};
