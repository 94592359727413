import React, { Fragment, useMemo, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useQuery } from "@apollo/client";
import { ImageUpload, Query } from "../../types";
import { Button, PRIMARY, SECONDARY, SMALL } from "../buttons/button";
import { ArtObjectOverlay } from "./contentComponents";
import { SEARCH_IMAGE_UPLOADS } from "../../graphql/queries";
import { ART_OBJECT_GALLERY_INCREMENT } from "../../constants";
import { UploadImageModal } from "./uploadImageModal";
import { ContentModalWrapper } from "./contentModalWrapper";
import { Filter } from "../inputs/filterBar";

type AddImageUploadType = {
  title: string;
  handleSubmit: (imageUploadIds: number[]) => void;
};

/** Uploaded Image content for the Add Content Modal
 * @param {AddImageUploadType} props
 * @prop {string} title - Title for the content page
 * @prop {(imageUploadIds: number[]) => void} handleSubmit - Function to be called on form submit
 */
export const AddImageUpload: React.FC<AddImageUploadType> = ({
  title,
  handleSubmit,
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [myImagesFilter, setMyImagesFilter] = useState<Filter>({
    active: false,
    filterName: "My images",
    value: "USER",
    id: "my-images",
  });
  const [filtersValue, setFiltersValue] = useState<Filter[]>([myImagesFilter]);
  const [images, setImages] = useState<ImageUpload[]>([]);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const toggleUploadForm = () => setShowUploadForm(!showUploadForm);

  const handleAddSelectedImage = (id: number, action: "add" | "remove") => {
    if (action === "add") {
      setSelected([...selected, id]);
    }
    if (action === "remove") {
      const updatedSelected = selected.filter((i) => i !== id);
      setSelected(updatedSelected);
    }
  };

  const handleFiltersArrayForSearch = useMemo(() => {
    let returnArray = [];
    filtersValue.forEach((filter) => {
      if (filter.active) {
        returnArray.push(filter.value);
      }
    });
    return returnArray;
  }, [filtersValue]);

  const handleSetImages = (newImages: ImageUpload[], clearObjects = false) => {
    let imgUploads: ImageUpload[];

    if (clearObjects) {
      imgUploads = newImages;
    } else {
      imgUploads = [...images, ...newImages];
    }

    setImages(imgUploads);
    setHasMoreResults(ART_OBJECT_GALLERY_INCREMENT === newImages.length);
  };

  const handleViewMore = async (e) => {
    const offset = images.length;
    const obj = await queryResult.fetchMore({
      variables: {
        paginate: { offset: offset, limit: ART_OBJECT_GALLERY_INCREMENT },
        term: searchValue,
        filter: handleFiltersArrayForSearch,
      },
    });
    handleSetImages(obj.data.searchImageUploads);
  };

  const handleSearch = async () => {
    const obj = await queryResult.fetchMore({
      variables: {
        paginate: { offset: 0, limit: ART_OBJECT_GALLERY_INCREMENT },
        term: searchValue,
        filter: handleFiltersArrayForSearch,
      },
    });
    handleSetImages(obj.data.searchImageUploads, true);
  };

  const getImageCaption = (image: ImageUpload): string => {
    if (image.caption) {
      return image.caption;
    } else {
      const fileName = image.uploadUrl.split("/");
      return fileName[fileName.length - 1];
    }
  };

  const getImageSource = (image: ImageUpload): string => {
    return image.thumbnailUrl ? image.thumbnailUrl : image.uploadUrl;
  };

  const queryResult = useQuery(SEARCH_IMAGE_UPLOADS, {
    variables: {
      paginate: {
        offset: 0,
        limit: ART_OBJECT_GALLERY_INCREMENT,
      },
      term: searchValue,
      permissions: handleFiltersArrayForSearch,
      notifyOnNetworkStatusChange: true,
    },
    onCompleted: (data: Pick<Query, "searchImageUploads">) => {
      handleSetImages(data.searchImageUploads, true);
    },
  });

  const handleFilterChange = (e, i) => {
    if (i === 0) {
      setMyImagesFilter(e);
    }
    setTimeout(() => {
      setFiltersValue([myImagesFilter]);
    }, 0);
  };

  const footer = (
    <Fragment>
      <Button
        btnType={SECONDARY}
        text="Upload"
        onClick={toggleUploadForm}
        customWidth="314px"
        id="upload-image-button"
      />
      <Button
        btnType={PRIMARY}
        text="Add images to canvas"
        onClick={() => handleSubmit(selected)}
        disabled={selected?.length === 0}
        customWidth="314px"
        id="add-images-to-canvas-button"
      />
    </Fragment>
  );

  return (
    <Fragment>
      <ContentModalWrapper
        title={title}
        handleSearch={handleSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleFilters={(e, i) => handleFilterChange(e, i)}
        queryResult={queryResult}
        footerChildren={footer}
        showFilter={true}
        filterArray={filtersValue}
      >
        {/* Display query results */}
        <div className="image-upload__body">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 500: 2, 650: 3 }}
          >
            <Masonry gutter="20px" columnsCount={3}>
              {images.map((image, index) => (
                <div className="art-object" key={index}>
                  <img
                    alt={image.caption}
                    src={getImageSource(image)}
                    className="art-object-image"
                  />
                  <ArtObjectOverlay
                    invno={image.id}
                    index={index}
                    caption={getImageCaption(image)}
                    handleAddContentClick={handleAddSelectedImage}
                  />
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
          <div className="art-object-gallery__footer">
            {hasMoreResults && (
              <div className="view-more-button">
                <Button
                  btnType={SECONDARY}
                  disabled={false}
                  size={SMALL}
                  text="View more"
                  onClick={handleViewMore}
                  id="add-image-view-more-button"
                />
              </div>
            )}
          </div>
        </div>
      </ContentModalWrapper>

      <UploadImageModal
        display={showUploadForm}
        onDismiss={toggleUploadForm}
        handleRefetchImages={queryResult.refetch}
      />
    </Fragment>
  );
};
