import React from "react";

type ModalFooterProps = {
  children: any;
};

/**
 * Component for VxP modal footer. To be wrapped in Modal component
 * @ModalFooterProps children
 */

export const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => {
  return <section className="modal_footer_section">{children}</section>;
};
