import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Canvas from "../../components/canvas/canvas";
import { PageWrapper } from "../../components/pageWrapper";
import Spinner from "../../components/spinner";
import { useAppContext } from "../../contexts/appContext";
import { ActionType, CanvasMode } from "../../contexts/types";
import { GET_MEETING_BY_TITLE } from "../../graphql/queries";
import { Meeting, Query, QueryGetMeetingByTitleArgs } from "../../types";
import {
  consoleNonProd,
  populateMeetingIdFromURL,
} from "../../utils/utilityBeltUtils";
import { diffBoards } from "../../utils/editClassUtils";
import { useError } from "../../contexts/errorContext";

export const EditClass: React.FC = () => {
  // get meetingId from the slug
  const path = useLocation().pathname;
  const meetingId = populateMeetingIdFromURL(path);
  const {
    setPresentationUpdated,
    setPresentationDiff,
    handleSetDbId,
    state,
    dispatch,
  } = useAppContext();
  const { showError } = useError();
  const [refetchBoards, setRefetchBoards] = useState(false);
  const [initialQuery, setInitialQuery] = useState(true);
  const [userUpdated, setUserUpdated] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  let t;

  const { loading, error, refetch, startPolling, stopPolling } = useQuery(
    GET_MEETING_BY_TITLE,
    {
      variables: {
        title: meetingId,
      } as QueryGetMeetingByTitleArgs,
      onCompleted(data: Pick<Query, "getMeetingByTitle">) {
        const meeting: Meeting = data.getMeetingByTitle;

        if (!userUpdated && !initialQuery) {
          // If the presentation was not updated by the current user, set presentation updated to true
          setPresentationDiff(diffBoards(state.slides, meeting.boards));
          setPresentationUpdated(true);
        } else {
          // Otherwise, switch the state back to false
          setUserUpdated(false);
        }

        dispatch({
          type: ActionType.HANDLE_GET_PRESENTATION,
          payload: {
            slides: meeting.boards,
            currentBoard: state.currentBoard
              ? state.currentBoard
              : meeting.boards[0],
          },
        });
        handleSetDbId(meeting.id);
        initialQuery && setInitialQuery(false);
      },
      onError(error) {
        consoleNonProd("Error fetching boards for meeting", meetingId, error);
        showError({
          error: "Something went wrong when fetching the board.",
        });
      },
    }
  );

  /** Reset canvas state and stop polling on unmount */
  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.SET_INIT_STATE,
      });
      stopPolling();
    };
  }, []);

  /** Set up polling */
  useEffect(() => {
    // Start polling server for changes every 10 seconds when the initial query is run
    startPolling(10000);
    setIsPolling(true);
  }, [initialQuery, startPolling]);

  /** Set up listeners page interaction listeners so reset the idle timer */
  useEffect(() => {
    window.addEventListener("load", resetTimer, true);
    window.addEventListener("mousemove", resetTimer, true);
    window.addEventListener("mousedown", resetTimer, true);
    window.addEventListener("touchstart", resetTimer, true);
    window.addEventListener("touchmove", resetTimer, true);
    window.addEventListener("click", resetTimer, true);
    window.addEventListener("keydown", resetTimer, true);
    window.addEventListener("scroll", resetTimer, true);
    window.addEventListener("wheel", resetTimer, true);
  }, []);

  useEffect(() => {
    if (refetchBoards) {
      refetch({
        title: meetingId,
      });
      setRefetchBoards(false);
    }
  }, [meetingId, refetch, refetchBoards]);

  // Resets idle timer to stop polling in 10 seconds
  const resetTimer = () => {
    // If we are currently not polling, start polling again
    if (!isPolling) startPolling(10000);

    clearTimeout(t);

    t = setTimeout(() => {
      stopPolling();
      setIsPolling(false);
    }, 10000);
  };

  const handleRefetchBoards = (newSelectedBoardNumber?: number) => {
    // Set that the current user has made this update
    setUserUpdated(true);
    refetch({ title: meetingId }).then((data) => {
      if (newSelectedBoardNumber && data?.data?.getMeetingByTitle) {
        dispatch({
          type: ActionType.UPDATE_CURRENT_BOARD,
          payload: {
            currentBoard:
              data.data.getMeetingByTitle.boards[newSelectedBoardNumber - 1],
          },
        });
      }
    });
  };

  return (
    <PageWrapper className="admin" navBar={true}>
      {loading ? (
        <Spinner />
      ) : (
        <Canvas
          mode={CanvasMode.EDIT}
          handleRefetchBoards={handleRefetchBoards}
          setShowChapterList={() => {
            consoleNonProd("edit class show chapter");
          }}
        />
      )}
    </PageWrapper>
  );
};
