import React, { Fragment, useState } from "react";
import { useLocalVideo } from "amazon-chime-sdk-component-library-react";
import { IconButton } from "../../buttons/iconButton";
import variables from "../../../styles/variables.scss";
import { Modal } from "../../modal/modal";
import { PopUp } from "../../popUps/popUp";
import { consoleNonProd } from "../../../utils/utilityBeltUtils";
import { browserName } from "react-device-detect";
import { Link } from "../../link";
import { useAppContext } from "../../../contexts/appContext";
import { HelperPopUp } from "../../popUps/helperPopUp";

type CameraToggleProps = {
  toolTip: "top" | "bottom" | "right" | "left" | "none";
};

export const CameraToggle: React.FC<CameraToggleProps> = ({ toolTip }) => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const [showVideoOffLockMessage, setShowVideoOffLockMessage] = useState(false);
  const [deniedModal, setDeniedModal] = useState(false);
  const [erorModal, setErrorModal] = useState(false);
  const { state, isInstructorOrModerator } = useAppContext();

  const deniedMessagePartOne =
    "To enjoy the full experience on our site you will need to update your browser settings to allow camera access. The site requires access to your camera so that the instructors and other participants can see you.";
  const deniedMessagePartTwo =
    "Or please reach out to our Student Help Desk line at 267.831.4464 or email support@barnesclasses.org";

  const errorMessage =
    "Something went wrong and we cannot access your camera. If you would like assistance please reach out to our Student Help Desk line at 267.831.4464 or email support@barnesclasses.org";

  const chromeHelpLink =
    "https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop";
  const edgeHelpLink =
    "https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857";
  const fireFoxHelpLink =
    "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions";
  const safariHelpLink =
    "https://support.apple.com/guide/mac-help/control-access-to-your-camera-mchlf6d108da/mac";

  const browserCheckForHelpLink = (): string => {
    if (browserName === "Edge") {
      return edgeHelpLink;
    }
    if (browserName === "Firefox") {
      return fireFoxHelpLink;
    }
    if (browserName === "Chrome") {
      return chromeHelpLink;
    }
    if (browserName === "Safari") {
      return safariHelpLink;
    }
  };

  const EdgePermissions = (
    <Fragment>
      {deniedMessagePartOne}
      <Link
        target="_blank"
        rel="noreferrer"
        href={browserCheckForHelpLink()}
        style={{
          margin: "1rem 0",
          display: "block",
        }}
        text={
          "Click here to learn how to enable camera access in " +
          browserName +
          "."
        }
      />
      {deniedMessagePartTwo}
    </Fragment>
  );

  function getPermissions() {
    // Immediately exit fn if the attendee is soft banned
    if (state.attendee.softBanned) return;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Request microphone access
      try {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(function (stream) {
            // camera access granted
            state.videoOffLock && !isInstructorOrModerator
              ? setShowVideoOffLockMessage(true)
              : toggleVideo();
          })
          .catch(function (error) {
            // camera access denied or an error occurred
            consoleNonProd("Error accessing camera:", error.message);
            if (
              error.name === "NotAllowedError" ||
              error.name === "PermissionDeniedError"
            ) {
              consoleNonProd("camera access denied by the user.");
              setDeniedModal(true);
              // Handle denial gracefully
            }
          });
      } catch (error) {
        setErrorModal(true);
        consoleNonProd("something went wrong accessing camera");
      }
    } else {
      // getUserMedia is not supported so throw the error modal
      consoleNonProd("getUserMedia is not supported in this browser.");
      setErrorModal(true);
    }
  }

  return (
    <>
      <IconButton
        iconName={isVideoEnabled ? "video" : "video-off"}
        onClick={getPermissions}
        desc={isVideoEnabled ? "Video off" : "Video on"}
        toolTip={toolTip}
        stroke={isVideoEnabled ? variables.actionGreen : variables.actionRed}
        btnId="enable-video-button"
        selected={isVideoEnabled}
      />
      {/* Modal for when user denied browser camera access */}
      <Modal
        dismissible={true}
        display={deniedModal || erorModal}
        onDismiss={() => {
          setDeniedModal(false);
          setErrorModal(false);
        }}
      >
        <div className="__modal-content">
          <PopUp
            popUpHeader="We're sorry, but it seems that camera access is currently denied."
            userMessage={deniedModal ? EdgePermissions : errorMessage}
            buttonText2="Okay"
            buttonType2="primary"
            onClick2={() => {
              setDeniedModal(false);
              setErrorModal(false);
            }}
          />
        </div>
      </Modal>
      {showVideoOffLockMessage && (
        <HelperPopUp
          popUpHeader="Class cameras disabled"
          userMessage="All cameras have been temporarily disabled by the teaching team."
          bottom="30px"
          right="-80px"
          downArrow={false}
          isDismissible={true}
          onDismiss={() => {
            setShowVideoOffLockMessage(false);
          }}
          isTimed={true}
          dismissTimeout={8}
          minWidth="250px"
          upArrow={false}
        />
      )}
    </>
  );
};
