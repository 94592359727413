import { useQuery } from "@apollo/client";
import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GET_REPLAY } from "../graphql/queries";
import { allowReplay, consoleNonProd } from "../utils/utilityBeltUtils";
import {
  FeatureFlagType,
  GenerateVttStatus,
  ProcessRecordingStatus,
  Query,
  QueryGetReplayMeetingArgs,
} from "../types";
import { useError } from "../contexts/errorContext";
import { adminRoutes, replayAllowedMeetings, routes } from "../constants";
import { useAuthContext } from "../contexts/authContext";
import { useFeatureContext } from "../contexts/featureContext";
import config from "../utils/config";
import { Replay } from "../components/replay/replay";
import Spinner from "../components/spinner";

export const Watch: React.FC = () => {
  const { showError } = useError();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const params = useParams();
  const meetingId = params.meetingId;

  // Feature flags
  const { featureFlags } = useFeatureContext();
  const replayFeatureActive = featureFlags.has(FeatureFlagType.REPLAY);
  const replayDemoActive = featureFlags.has(FeatureFlagType.REPLAY_DEMO);

  // Fetch meeting info from the server
  const { loading, data } = useQuery(GET_REPLAY, {
    fetchPolicy: "no-cache",
    variables: {
      title: meetingId,
    } as QueryGetReplayMeetingArgs,
    onCompleted(data: Pick<Query, "getReplayMeeting">) {
      const meeting = data?.getReplayMeeting;
      const mediaPipeline = data?.getReplayMeeting?.mediaPipelines[0];

      // Check to see if the meeting ID is in the allow list or if the user is authenticated
      const allowMeetingReplay =
        allowReplay(meetingId, replayAllowedMeetings) || isAuthenticated;

      // Whether or not the meeting can be viewed with replay feature flag off
      const allowReplayFeatureInactive =
        !replayFeatureActive && allowMeetingReplay;

      // Check to see if the captions have been successfully generated in the prod env
      const captionsSuccess =
        config.env.toLowerCase() === "production"
          ? mediaPipeline?.generateCaptionsStatus === GenerateVttStatus.SUCCESS
          : true;
      // Check to see if the events have been successfully generated
      const eventsSuccess =
        mediaPipeline?.generateEventsStatus === GenerateVttStatus.SUCCESS;

      // Check to see if meeting should use "simple view"
      const simpleViewMeeting =
        !meeting.instructorAV && !meeting.studentAV && !meeting.chat;

      // Check to see if the recording has successfully been generated
      // or if the there was a manual update
      // or if the recording was replaced
      // or if there was an error but the meeting was a simple view
      const recordingSuccess =
        mediaPipeline?.processRecordingStatus ===
          ProcessRecordingStatus.SUCCESS ||
        mediaPipeline?.processRecordingStatus ===
          ProcessRecordingStatus.MANUAL_UPDATE ||
        mediaPipeline?.processRecordingStatus ===
          ProcessRecordingStatus.REPLACED ||
        simpleViewMeeting;
      // Whether or not the replay has the required elements to be viewed
      const hasRequiredElements =
        captionsSuccess && eventsSuccess && recordingSuccess;

      // Check if the meeting is the replay demo
      const isReplayDemo = meetingId === adminRoutes.replayDemo;

      // Check to see if this meeting is available for replay
      if (
        // Replay feature flag is on OR this meeting can be viewed with the flag off...
        ((replayFeatureActive || allowReplayFeatureInactive) &&
          // this meeting has a media pipeline returned from the server AND...
          mediaPipeline &&
          // the replay has the required elements (video, events, and captions) AND...
          hasRequiredElements &&
          // the meeting is not the replay demo meeting... OR
          !isReplayDemo) ||
        // The replay demo feature flag is on AND the meeting is the replay demo meeting...
        (replayDemoActive && isReplayDemo)
      ) {
        if (mediaPipeline.replayRecording) {
          // Set state!
        }
      }
      // Otherwise show an error and then redirect to /join page
      else {
        showError({
          header: "On demand unavailable!",
          error: "Recording for this meeting is not available.",
          onClick1: () => navigate(routes.join),
        });
      }
    },
    onError(error) {
      consoleNonProd("Error fetching meeting", meetingId, error);
      showError({
        error: "Something went wrong when fetching the meeting.",
      });
    },
  });

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Replay replayMeeting={data?.getReplayMeeting} />
      )}
    </Fragment>
  );
};
