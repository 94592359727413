import React, { Fragment, Suspense, useMemo, useState } from "react";
import { useAppContext } from "../../contexts/appContext";
import { Chat } from "../chat";
import { ChatToggle } from "./controls/chatToggle";
import classnames from "classnames";
import { LowerAllHandsButton } from "./controls/lowerAllHands";
import { MuteAllButton } from "./controls/muteAllButton";
import { ParticipantList } from "../participantList";
import { RepeatLastEventButton } from "./controls/repeatLastEventButton";
import { useDataEvents } from "../../providers/dataEvents";
import { CanvasDataEvent, EventTypes } from "../../providers/types";
import { LaserPointerButton } from "./controls/laserToggle";
import { useFeatureContext } from "../../contexts/featureContext";
import { FeatureFlagType } from "../../types";
import { ToolBar } from "./toolBar";
import Spinner from "../spinner";
import { IconButton } from "../buttons/iconButton";
import { MoreActionsButton } from "./controls/moreActions/moreActionsButton";
import { MoreActionsWrapper } from "./controls/moreActions/moreActionsWrapper";
import { MoreActionsItem } from "./controls/moreActions/moreActionsItem";
import { FullScreenToggle } from "./controls/fullScreenToggle";
import { MobileParticipantsToggle } from "./mobile/mobileParticipantsToggle";
import { HelperPopUp } from "../popUps/helperPopUp";
import { useScreenContext } from "../../contexts/screenContext";
import { ActionType, AttendeeState } from "../../contexts/types";
import { ChatReplayMessage } from "../../providers/replayDataMessages";
import { ChatDataMessage } from "../../providers/dataMessages";
import { consoleNonProd } from "../../utils/utilityBeltUtils";
import { VideoOffAllButton } from "./controls/videoAllOff";
import { MeetingOptions } from "../meetingOptions";

type EngagementToolsProps = {
  events?: CanvasDataEvent[];
  setShowInstructors: (show: boolean) => void;
  showInstructors: boolean;
  toggleInstructors: () => void;
  messages: ChatReplayMessage[] | ChatDataMessage[];
  sendMessage?: (message: string, attendee?: AttendeeState) => void;
  messageCount: number;
};

/** Toolbar component for edit presentation tools, Add Content, Edit Board, Add Board
 * @param {EngagementToolsProps} props
 * @prop {CanvasDataEvent[]} events - List of canvas events that record the leaders' actions
 * @prop { (show: boolean) => void} setShowInstructors - sets Instructor videos
 * @prop {boolean} showInstructors - shows Instructor videos
 * @prop {() => void} toggleInstructors - toggles Instructor videos
 * @prop {messages[]} ChatDataMessage[] or ChatReplayMessage[] - List of messages DataMessages provider
 * @prop {() => Promise<void>} sendMessage - Optional function that is called when you send a message
 */

export const EngagementTools: React.FC<EngagementToolsProps> = ({
  events,
  setShowInstructors,
  showInstructors,
  toggleInstructors,
  messages,
  sendMessage,
  messageCount,
}) => {
  const {
    showChat,
    setShowChat,
    setShowUserVideo,
    showParticipants,
    state,
    dispatch,
    isInstructorAVMeeting,
    isStudentAVMeeting,
    meetingHasChat,
  } = useAppContext();
  const { useMobileTools } = useScreenContext();
  const { featureFlags } = useFeatureContext();
  const laserPointerFFActive = featureFlags.has(FeatureFlagType.LASER_POINTER);
  const [expandedMenu, setExpandedMenu] = useState(false);
  const [showEngagementChat, setShowEngagementChat] = useState(false);
  const [showEngagementRoster, setShowEngagementRoster] = useState(false);
  const [showEngagementModTools, setShowEngagementModTools] = useState(false);
  const [showRoster, setShowRoster] = useState(false);
  const [showMeetingOptions, setShowMeetingOptions] = useState(false);
  const { sendEvent } = useDataEvents();
  const [showMoreActionsMenu, setShowMoreActionsMenu] = useState(false);

  useMemo(() => {
    if (showChat) {
      setExpandedMenu(true);
      setShowEngagementRoster(false);
      setTimeout(() => {
        setShowEngagementChat(true);
      }, 600);
    } else if (showRoster) {
      setExpandedMenu(true);
      setShowEngagementChat(false);
      setTimeout(() => {
        setShowEngagementRoster(true);
      }, 600);
    } else if (showMeetingOptions) {
      setExpandedMenu(true);
      setShowEngagementChat(false);
      setTimeout(() => {
        setShowEngagementModTools(showMeetingOptions);
      }, 600);
    } else {
      setExpandedMenu(false);
      setShowEngagementChat(false);
      setShowEngagementRoster(false);
      setShowEngagementModTools(false);
    }
  }, [
    showChat,
    showRoster,
    showMeetingOptions,
    setShowEngagementChat,
    setShowEngagementModTools,
    setExpandedMenu,
  ]);

  /** Handles sending instructor spotlight change event */
  const handleToggleSpotlightInstructor = () => {
    // setShowInstructors to true
    // so that if a student has this set to false
    // they don't just get a blurred canvas with no instructor video on spotlight mode
    setShowInstructors(true);
    // set to true so if an instructor hid their video, it will become visible again for spotlight mode
    setShowUserVideo(true);
    sendEvent &&
      sendEvent(
        {
          type: EventTypes.SPOTLIGHT_INSTRUCTOR,
          leader: true,
          spotlightInstructor: !state.spotlightInstructor,
          exploreMode: !state.spotlightInstructor ? false : state.exploreMode,
        },
        state
      );
    dispatch({
      type: ActionType.UPDATE_SPOTLIGHT_INSTRUCTOR,
      payload: {
        spotlightInstructor: !state.spotlightInstructor,
      },
    });
  };

  return (
    <div
      className={classnames("engagement-tools-container", {
        "engagement-tools-container__expanded": expandedMenu,
      })}
    >
      <section
        className={classnames("engagement-tools-container__left", {
          "engagement-tools-container__expanded--left": expandedMenu,
        })}
      >
        {showChat && showEngagementChat && meetingHasChat && (
          <Chat
            className="engagement-chat"
            onDismiss={() => {
              setShowEngagementChat(false);
            }}
            height="415px"
            messages={messages}
            sendMessage={sendMessage}
            messageCount={messageCount}
          />
        )}
        {showRoster && showEngagementRoster && (
          <ParticipantList setShowRoster={setShowRoster} />
        )}
        {showMeetingOptions && showEngagementModTools && <MeetingOptions />}
      </section>

      <ToolBar
        orientation="vertical"
        className={classnames("engagement-tools-container__right", {
          "engagement-tools-container__expanded--right": expandedMenu,
        })}
      >
        <Suspense fallback={<Spinner />}>
          <IconButton
            onClick={(e) => {
              setShowRoster(!showRoster);
              setShowChat(false);
              setShowEngagementChat(false);
              setShowMeetingOptions(false);
            }}
            iconName="list"
            desc={showRoster ? "Hide roster" : "Show roster"}
            toolTip="left"
            selected={showRoster}
          />

          <Fragment>
            {meetingHasChat && (
              <ChatToggle
                toolTip="left"
                emitClick={(e) => {
                  setShowRoster(false);
                  setShowMeetingOptions(false);
                }}
                messages={messages}
                component="ICON_BUTTON"
                chatMessagesCount={messageCount}
              />
            )}
            <IconButton
              onClick={(e) => {
                setShowRoster(false);
                setShowChat(false);
                setShowEngagementChat(false);
                setShowMeetingOptions(!showMeetingOptions);
              }}
              iconName="tool"
              desc={
                showMeetingOptions
                  ? "Hide meeting options"
                  : "Show meeting options"
              }
              toolTip="left"
              selected={showMeetingOptions}
            />
            <span className="divider" />
            <LowerAllHandsButton
              toolTip="left"
              emitClick={(e) => consoleNonProd("All hands lowered")}
            />
            {isStudentAVMeeting && (
              <MuteAllButton
                toolTip="left"
                emitClick={() => consoleNonProd("Muted all")}
              />
            )}
            {isStudentAVMeeting && (
              <VideoOffAllButton
                toolTip="left"
                onClick={() => consoleNonProd("Video Off all")}
              />
            )}
            {isInstructorAVMeeting && (
              <IconButton
                iconName={
                  state.spotlightInstructor
                    ? "collapse-picture"
                    : "expand-picture"
                }
                onClick={handleToggleSpotlightInstructor}
                desc={
                  state.spotlightInstructor
                    ? "End instructor spotlight"
                    : "Begin instructor spotlight"
                }
                toolTip="left"
                btnId="instructor-spotlight-button"
                selected={state.spotlightInstructor}
              />
            )}
          </Fragment>

          <RepeatLastEventButton
            toolTip="left"
            emitClick={() => consoleNonProd("Repeated last event")}
            lastEvent={events ? events[events?.length - 1] : undefined}
          />
          {laserPointerFFActive && <LaserPointerButton toolTip="left" />}
          <span className="divider" />
          {(isStudentAVMeeting || isInstructorAVMeeting) && (
            <>
              <MoreActionsButton
                showActionsInput={showMoreActionsMenu}
                toolTip="left"
                alignment="vertical"
                id="engagement-tools__more-actions"
                onClick={(e) => setShowMoreActionsMenu(!showMoreActionsMenu)}
              >
                <MoreActionsWrapper
                  itemClick={(e) => {
                    e.preventDefault();
                    setShowMoreActionsMenu(false);
                  }}
                  upOrDown="up"
                  leftOrRight="left"
                >
                  <MoreActionsItem id="engagement-tools__more-actions__mobile-participants-toggle">
                    <MobileParticipantsToggle
                      showInstructors={showInstructors}
                      toggleInstructors={toggleInstructors}
                    />
                  </MoreActionsItem>
                  <MoreActionsItem id="engagement-tools__more-actions__full-screen">
                    <FullScreenToggle component="BUTTON" />
                  </MoreActionsItem>
                </MoreActionsWrapper>
              </MoreActionsButton>

              {!showParticipants && (
                <HelperPopUp
                  userMessage={`${
                    useMobileTools ? "Tap" : "Click"
                  } here to show student videos`}
                  bottom={"5px"}
                  right={"40px"}
                  rightArrow={true}
                  isDismissible={false}
                  onDismiss={() => null}
                  isTimed={true}
                  minWidth="255px"
                  arrowRight="4px"
                />
              )}
            </>
          )}
          {!isStudentAVMeeting && !isInstructorAVMeeting && (
            <FullScreenToggle component="ICON_BUTTON" />
          )}
        </Suspense>
      </ToolBar>
    </div>
  );
};
