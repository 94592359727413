import React, { useEffect, useState } from "react";
import { Modal } from "../modal/modal";
import { ModalHeader } from "../modal/modalHeader";
import { ModalBody } from "../modal/modalBody";
import { Button, PRIMARY, SECONDARY, SMALL, STANDARD } from "../buttons/button";
import {
  GenerateVttStatus,
  MediaPipeline,
  Meeting,
  Mutation,
  SetReplayRecordingPipelineInput,
} from "../../types";
import { convertUTCtoDateTime } from "../../utils/dateUtils";
import { Checkbox } from "../inputs/checkbox";
import { ModalFooter } from "../modal/modalFooter";
import { getObject } from "../../utils/s3Utils";
import config from "../../utils/config";
import { consoleNonProd, download } from "../../utils/utilityBeltUtils";
import { IconButton } from "../buttons/iconButton";
import { useMutation } from "@apollo/client";
import { SET_REPLAY_RECORDING_PIPELINE } from "../../graphql/mutations";
import { useError } from "../../contexts/errorContext";

type RecordingDetailsModalProps = {
  display: boolean;
  onDismiss: () => void;
  meeting: Meeting;
  handleSetMeetingInfo: (meeting: Meeting) => void;
  replayRecording?: MediaPipeline;
};

export const RecordingDetailsModal: React.FC<RecordingDetailsModalProps> = ({
  display,
  onDismiss,
  meeting,
  handleSetMeetingInfo,
  replayRecording,
}) => {
  const { showError } = useError();
  const [selectedReplayRecording, setSelectedReplayRecording] = useState<
    MediaPipeline | undefined
  >(meeting.mediaPipelines?.find((mp) => mp.replayRecording));

  const [setReplayRecordingPipelineMutation] = useMutation(
    SET_REPLAY_RECORDING_PIPELINE,
    {
      onCompleted(data: Pick<Mutation, "setReplayRecordingPipeline">) {
        const meeting = data.setReplayRecordingPipeline;
        // Update meeting details in Course page component
        handleSetMeetingInfo(meeting);
      },
      onError(error) {
        showError({
          error:
            'Something went wrong while updating the replay video. Click "View details" to learn more.',
          details: error,
        });
      },
    }
  );

  const handleDownloadChat = async (mediaPipeline: MediaPipeline) => {
    const date = convertUTCtoDateTime(mediaPipeline.createdAt).replaceAll(
      /[\/\s\:\,]/gm,
      "-"
    );
    const filename = `${date}_chat.txt`;
    const chatObjKey = `${mediaPipeline.awsConcatPipelineId}/logs/chat-log.txt`;
    try {
      const resp = await getObject(config.concatS3Bucket, chatObjKey);
      // @ts-ignore
      const content = await resp.body.transformToString();
      download(filename, content);
    } catch (e) {
      consoleNonProd(
        `Error downloading chat for pipeline ID ${mediaPipeline.id} from S3:`,
        e
      );
    }
  };

  const handleDownloadAttendees = async (mediaPipeline: MediaPipeline) => {
    const filename = `attendee_log_${mediaPipeline.awsConcatPipelineId}.csv`;
    const attendeesObjKey = `${mediaPipeline.awsConcatPipelineId}/logs/attendee_log_${mediaPipeline.awsConcatPipelineId}.csv`;
    try {
      const resp = await getObject(config.concatS3Bucket, attendeesObjKey);
      // @ts-ignore
      const content = await resp.body.transformToString();
      download(filename, content);
    } catch (e) {
      consoleNonProd(
        `Error downloading attendee log for pipeline ID ${mediaPipeline.id} from S3:`,
        e
      );
    }
  };

  const handleSelectReplayRecording = (mp: MediaPipeline) => {
    // Update the replay recording with the one passed
    if (selectedReplayRecording !== mp) {
      setSelectedReplayRecording(mp);
    }
    // Or deselect the current replay recording
    else {
      setSelectedReplayRecording(undefined);
    }
  };

  const handleSubmit = () => {
    const input: SetReplayRecordingPipelineInput = {
      id: meeting.id,
      mediaPipelineId: selectedReplayRecording?.id,
    };

    setReplayRecordingPipelineMutation({ variables: { input } });
    onDismiss();
  };

  return (
    <Modal dismissible={true} display={display} onDismiss={onDismiss}>
      <div className="recording-details__modal">
        <ModalHeader>
          <h3>Class recording details</h3>
        </ModalHeader>
        <ModalBody>
          <table className="recording-details__table">
            <thead>
              <tr>
                <th className="recording-details__table__cell">
                  Recording date
                </th>
                <th className="recording-details__table__cell">Chat Log</th>
                <th className="recording-details__table__cell">
                  Attendee List
                </th>
                <th className="recording-details__table__cell">
                  Use recording for replay
                </th>
                <th className="recording-details__table__cell">
                  Replay processing status
                </th>
              </tr>
            </thead>
            <tbody>
              {meeting.mediaPipelines?.map((mp, i) => (
                <tr key={i} className="">
                  <td className="recording-details__table__cell">
                    <span className="recording-details__date">
                      {convertUTCtoDateTime(mp.createdAt)}
                    </span>
                    <div className="recording-details__meta">
                      <span>Pipeline id: {mp.id}</span>
                      <span>Concat folder: {mp.awsConcatPipelineId}</span>
                    </div>
                  </td>
                  <td className="recording-details__table__cell">
                    {(mp.chatLogStatus === GenerateVttStatus.SUCCESS ||
                      mp.chatLogStatus === GenerateVttStatus.MANUAL_UPDATE) && (
                      <IconButton
                        onClick={(_e) => handleDownloadChat(mp)}
                        size={SMALL}
                        iconName="download"
                        desc="Download chat log"
                        btnId={`download-chat--${mp.createdAt}`}
                      />
                    )}
                  </td>
                  <td className="recording-details__table__cell">
                    {(mp.attendeeLogStatus === GenerateVttStatus.SUCCESS ||
                      mp.attendeeLogStatus ===
                        GenerateVttStatus.MANUAL_UPDATE) && (
                      <IconButton
                        onClick={(_e) => handleDownloadAttendees(mp)}
                        size={SMALL}
                        iconName="download"
                        desc="Download attendee list"
                        btnId={`download-attendee--${mp.createdAt}`}
                      />
                    )}
                  </td>
                  <td className="recording-details__table__cell">
                    <Checkbox
                      value={mp.id}
                      checked={mp.id === selectedReplayRecording?.id}
                      id={`mp_replay-recording_${i}`}
                      onChange={() => handleSelectReplayRecording(mp)}
                    />
                  </td>
                  <td className="recording-details__table__cell recording-details__status">
                    Video: {mp.processRecordingStatus}
                    <br />
                    Events: {mp.generateEventsStatus}
                    <br />
                    Chapters: {mp.generateChaptersStatus}
                    <br />
                    Transcript: {mp.generateCaptionsStatus}
                    <br />
                    Chat: {mp.chatLogStatus}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <div className="pop-up__buttons">
            <Button
              btnType={SECONDARY}
              disabled={false}
              size={STANDARD}
              text="Close"
              onClick={onDismiss}
              id="popup-footer-first-button"
            />
            <Button
              btnType={PRIMARY}
              disabled={replayRecording === selectedReplayRecording}
              size={STANDARD}
              text="Update replay recording"
              onClick={handleSubmit}
              id="popup-footer-second-button"
            />
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
};
