import { Attendee, Board, Meeting } from "../types";

/**
 * ENUMS
 */
export enum CanvasMode {
  EDIT,
  VIEW_ONLY,
  PRESENTATION,
  REPLAY,
}

export enum MeetingMode {
  Spectator,
  Attendee,
}

export enum MeetingRole {
  Audience,
  Presenter,
  Moderator,
}

export enum NavEvents {
  FIT = "FIT",
  ZOOM_IN = "ZOOM_IN",
  ZOOM_OUT = "ZOOM_OUT",
}

/**
 * TYPES
 */
export type CanvasNavEvent = {
  [key in NavEvents]: (state: State) => void;
};

export type JoinInfoType = {
  Meeting: Meeting & MediaPlacementType;
  Attendee: Omit<Attendee, "meeting">;
};

export type MediaPlacementType = {
  MediaPlacement: {
    AudioHostUrl: Meeting["AudioHostUrl"];
    SignalingUrl: Meeting["SignalingUrl"];
    TurnControlUrl: Meeting["TurnControlUrl"];
  };
};

export type Participant = {
  AttendeeId?: string;
  ExternalUserId?: string;
  externalUserId?: string;
  firstName?: string;
  iconColor?: string;
  id?: number;
  lastName?: string;
  meetingRole?: number;
  __typename?: string;
  name?: string;
  tileId?: number;
  chimeAttendeeId?: string;
  JoinToken?: string;
  handIsRaised: boolean;
  signalStrength?: number;
  connectionQuality?: string;
  muted?: boolean;
  videoEnabled?: boolean;
  speaking?: boolean;
  softBanned?: boolean;
};

/**
 * APP CONTEXT REDUCER ENUM AND TYPES
 */
export enum ActionType {
  // EVENT = actions triggered by a incoming canvas event
  EVENT_TOGGLE_EXPLORE_MODE,
  EVENT_TOGGLE_LASER_POINTER,
  // HANDLE = updates multiple items
  HANDLE_CANVAS_INIT_LOAD,
  HANDLE_GET_PRESENTATION,
  HANDLE_INCOMING_EVENT,
  // SET_INIT_STATE = sets state to init state
  SET_INIT_ATTENDEE_STATE,
  SET_INIT_STATE,
  // UPDATE = updates single item
  UPDATE_ATTENDEE_SOFT_BAN,
  UPDATE_CURRENT_BOARD,
  UPDATE_EXPLORE_MODE,
  UPDATE_REPLAY_EXPLORE,
  UPDATE_LASER_INSTRUCTOR,
  UPDATE_PRESENTATION_VERSION,
  UPDATE_SHOW_LASER,
  UPDATE_SLIDES,
  UPDATE_SPOTLIGHT_INSTRUCTOR,
  UPDATE_MUTE_LOCK,
  UPDATE_VIDEO_OFF_LOCK,
}

export type Action =
  | EventToggleExploreModeAction
  | EventToggleLaserPointerAction
  | HandleCanvasInitLoadAction
  | HandleGetPresentationAction
  | HandleIncomingEventAction
  | SetInitAttendeeStateAction
  | SetInitStateAction
  | UpdateAttendeeSoftBanAction
  | UpdateCurrentBoardAction
  | UpdateExploreModeAction
  | UpdateLaserInstructorAction
  | UpdatePresentationVersionAction
  | UpdateShowLaserAction
  | UpdateSlidesAction
  | UpdateSpotlightInstructorAction
  | UpdateReplayExploreAction
  | UpdateMuteLockAction
  | UpdateVideoOffLockAction;

export type AttendeeState = {
  softBanned: boolean;
};

export type State = {
  currentBoard?: Board;
  exploreMode: boolean;
  laserInstructor?: string;
  presentationVersion?: number;
  showLaser: boolean;
  slides?: Board[];
  spotlightInstructor: boolean;
  manualSpotlight?: boolean;
  replayExplore?: boolean;
  muteLock?: boolean;
  attendee?: AttendeeState;
  videoOffLock?: boolean;
};

// Reducer action types
export type EventToggleExploreModeAction = {
  type: ActionType.EVENT_TOGGLE_EXPLORE_MODE;
  payload: {
    exploreMode: boolean;
    showLaser?: boolean;
    laserInstructor?: string;
    replayExplore?: boolean;
    currentBoard?: Board;
  };
};

export type EventToggleLaserPointerAction = {
  type: ActionType.EVENT_TOGGLE_LASER_POINTER;
  payload: {
    showLaser: boolean;
    laserInstructor: string;
  };
};

export type HandleCanvasInitLoadAction = {
  type: ActionType.HANDLE_CANVAS_INIT_LOAD;
  payload: {
    exploreMode: boolean;
    spotlightInstructor: boolean;
  };
};

export type HandleGetPresentationAction = {
  type: ActionType.HANDLE_GET_PRESENTATION;
  payload: {
    currentBoard: Board;
    slides: Board[];
    muteLock?: boolean;
    videoOffLock?: boolean;
  };
};

export type HandleIncomingEventAction = {
  type: ActionType.HANDLE_INCOMING_EVENT;
  payload: State;
};

export type SetInitAttendeeStateAction = {
  type: ActionType.SET_INIT_ATTENDEE_STATE;
  payload?;
};

export type SetInitStateAction = {
  type: ActionType.SET_INIT_STATE;
  payload?;
};

export type UpdateAttendeeSoftBanAction = {
  type: ActionType.UPDATE_ATTENDEE_SOFT_BAN;
  payload: {
    softBan: boolean;
  };
};

export type UpdateCurrentBoardAction = {
  type: ActionType.UPDATE_CURRENT_BOARD;
  payload: { currentBoard: Board };
};

export type UpdateExploreModeAction = {
  type: ActionType.UPDATE_EXPLORE_MODE;
  payload: { exploreMode: boolean };
};

export type UpdateReplayExploreAction = {
  type: ActionType.UPDATE_REPLAY_EXPLORE;
  payload: { replayExplore: boolean };
};

export type UpdateMuteLockAction = {
  type: ActionType.UPDATE_MUTE_LOCK;
  payload: { muteLock: boolean };
};

export type UpdateVideoOffLockAction = {
  type: ActionType.UPDATE_VIDEO_OFF_LOCK;
  payload: { videoOffLock: boolean };
};

export type UpdateLaserInstructorAction = {
  type: ActionType.UPDATE_LASER_INSTRUCTOR;
  payload: { laserInstructor: string };
};

export type UpdatePresentationVersionAction = {
  type: ActionType.UPDATE_PRESENTATION_VERSION;
  payload: { presentationVersion: number };
};

export type UpdateShowLaserAction = {
  type: ActionType.UPDATE_SHOW_LASER;
  payload: { showLaser: boolean };
};

export type UpdateSlidesAction = {
  type: ActionType.UPDATE_SLIDES;
  payload: { slides: Board[] };
};

export type UpdateSpotlightInstructorAction = {
  type: ActionType.UPDATE_SPOTLIGHT_INSTRUCTOR;
  payload: { spotlightInstructor: boolean; manualSpotlight?: boolean };
};

/**
 * ERROR BOUNDARY REDUCER ENUM AND TYPES
 */
export enum ErrorActionType {
  RESET,
  SHOW_ERROR,
}

export type ErrorAction = ResetErrorAction | ShowErrorAction;

export type ErrorState = {
  hasError: boolean;
  header: string;
  error: any;
  details?: any;
  button1: string;
  button2?: string;
  onClick1?: () => void;
  onClick2?: () => void;
};

export type ResetErrorAction = {
  type: ErrorActionType.RESET;
  payload?: any;
};

export type ShowErrorAction = {
  type: ErrorActionType.SHOW_ERROR;
  payload: {
    header?: string;
    error?: any;
    details?: any;
    button1?: string;
    button2?: string;
    onClick1?: () => void;
    onClick2?: () => void;
  };
};
