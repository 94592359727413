import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import classnames from "classnames";
import { Button, PRIMARY, SECONDARY } from "../buttons/button";
import { ModalBody } from "../modal/modalBody";
import { ModalFooter } from "../modal/modalFooter";
import { ModalHeader } from "../modal/modalHeader";
import { Modal } from "../modal/modal";
import { addFile, createAlbum } from "../../utils/s3Utils";
import { CreateImageUploadInput, Mutation } from "../../types";
import { BULK_CREATE_IMAGE_UPLOADS } from "../../graphql/mutations";
import Spinner from "../spinner";
import { ApolloErrorsList } from "../apolloErrorsList";
import { validateImageForConversion } from "../../utils/validators";
import { IconButton } from "../buttons/iconButton";
import SimpleBar from "simplebar-react";
import { consoleNonProd } from "../../utils/utilityBeltUtils";

type UploadImageModalProps = {
  display: boolean;
  onDismiss: () => void;
  handleRefetchImages: () => void;
};

/** Modal that contains the upload image form
 * On submit, this will create a new album in S3 for each image, upload each
 * image to its folder, and then run the mutation save the image location in the db
 * @param {UploadImageModalProps} props
 * @prop {boolean} display - Whether or not the modal is displayed
 * @prop {() => void} onDismiss - Function that is called when the modal is dismissed
 * @prop {() => void} handleRefetchImages - Function that is called after the new images
 * have been added to the DB to refresh the ImageUpload list in the AddImageUpload component
 */
export const UploadImageModal: React.FC<UploadImageModalProps> = ({
  display,
  onDismiss,
  handleRefetchImages,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [dragActive, setDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [tooManyFilesError, setTooManyFilesError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidFiles, setInvalidFiles] = useState<File[]>([]);

  const submitButtonText = `Upload ${
    files?.length > 0 ? files?.length : ""
  } image${files?.length > 1 ? "s" : ""}`;

  const tooManyFilesText = "Only 15 images or less allowed";

  const [bulkCreateImageUploadsMutation, { error }] = useMutation(
    BULK_CREATE_IMAGE_UPLOADS,
    {
      async onCompleted(_data: Pick<Mutation, "bulkCreateImageUploads">) {
        // trigger reload of all images in the AddImageUpload component
        await handleRefetchImages();
        setIsLoading(false);
        setFiles([]);
        onDismiss();
      },
      onError(error) {
        consoleNonProd("Error in bulk upload images mutation:", error);
        setIsLoading(false);
        setShowError(true);
      },
    }
  );

  const handleDrag = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleSetFiles = (fileList: FileList) => {
    const chosenFiles = Array.prototype.slice.call(fileList);
    const validFiles = chosenFiles.filter((f) =>
      validateImageForConversion(f.name)
    );
    const invalid = chosenFiles.filter(
      (f) => !validateImageForConversion(f.name)
    );

    setFiles([...files, ...validFiles]);
    setInvalidFiles(invalid);
  };

  const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleSetFiles(e.dataTransfer.files);
    }
  };

  const handleChange = (e) => {
    handleSetFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    const input: CreateImageUploadInput[] = [];
    setIsLoading(true);

    for (const file of files) {
      try {
        // Create S3 Album for the image file
        const createAlbumRes = await createAlbum(file.name);

        if (!createAlbumRes.success) {
          // handle album creation error
          setShowError(true);
          setErrorMessage(createAlbumRes.message);
          setIsLoading(false);
          // Break out of fn
          return;
        }

        // Upload image to S3 and get the object url
        const uploadImageResp = await addFile(createAlbumRes.album, file);

        if (!uploadImageResp.success) {
          // handle upload image error
          setShowError(true);
          setErrorMessage(uploadImageResp.message);
          setIsLoading(false);
          // Break out of fn
          return;
        }

        // add image details to input array
        input.push({ uploadUrl: uploadImageResp.fileName });
      } catch (e) {
        // handle errors
        consoleNonProd("Error creating image uploads", e);
        setShowError(true);
        setIsLoading(false);
        setErrorMessage(JSON.stringify(e));
      }
    }

    // call bulk create mutation to save the urls and trigger thumbnail and IIIF conversions
    bulkCreateImageUploadsMutation({ variables: { input } });
  };

  const handleDismiss = (_e) => {
    // Clear state on dismiss
    setShowError(false);
    setErrorMessage("");
    setIsLoading(false);
    setFiles([]);
    setDragActive(false);
    setErrorMessage("");
    onDismiss();
  };

  useEffect(() => {
    if (files.length > 15) {
      setTooManyFilesError(true);
    } else {
      setTooManyFilesError(false);
    }
  }, [files]);

  return (
    <Modal dismissible={true} display={display} onDismiss={handleDismiss}>
      <div className="add-content upload-image-modal">
        <ModalHeader>
          <h1>Upload Image</h1>
        </ModalHeader>
        <ModalBody>
          {isLoading && !showError ? (
            <Spinner />
          ) : showError ? (
            // Display GQL errors when there is an issue with the query
            <div className="art-object-gallery__no-results">
              <div className="no-results-message upload-image-modal__error">
                <p>
                  The following errors prevented the image
                  {files?.length > 1 ? "s" : ""} from being uploaded:
                </p>
                {error?.graphQLErrors && <ApolloErrorsList error={error} />}
                {errorMessage && <div>{errorMessage}</div>}
              </div>
            </div>
          ) : (
            <form
              id="upload-image-modal__form"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
              <label
                className={classnames("upload-image-modal__label", {
                  "drag-active": dragActive,
                })}
                htmlFor="upload-image-modal__input"
              >
                <div>
                  <p>
                    Drag and drop your file(s) here or click to open the file
                    browser (15 file limit)
                  </p>

                  {files?.length > 0 && (
                    <SimpleBar
                      className={classnames("upload-image-modal__label--list", {
                        "upload-image-modal__label--list-error":
                          invalidFiles?.length || tooManyFilesError,
                      })}
                      forceVisible={false}
                      autoHide={true}
                    >
                      Selected files: {files.length}
                      <ul style={{ listStyleType: "none" }}>
                        {files.map((file, i: number) => (
                          <li key={i}>
                            <IconButton
                              onClick={(e) => {
                                setFiles((oldFiles) =>
                                  oldFiles.filter(
                                    (newFile, index) => index !== i
                                  )
                                );
                              }}
                              iconName={"trash-2"}
                              desc={"remove image"}
                            />
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    </SimpleBar>
                  )}
                  {tooManyFilesError && (
                    <div className="upload-image-modal__error">
                      <p>
                        Oh no, this is too many files please remove{" "}
                        {files.length - 15} file(s)
                      </p>
                    </div>
                  )}
                  {invalidFiles?.length > 0 && (
                    <div className="upload-image-modal__error">
                      <p>
                        Only files with types &quot;.jpg&quot;,
                        &quot;.jpeg&quot;, &quot;.png&quot;, or&quot; are
                        accepted.
                      </p>
                      The following files were not selected:
                      <ul>
                        {invalidFiles.map((file, i) => (
                          <li key={i}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </label>
              <input
                type="file"
                id="upload-image-modal__input"
                multiple={true}
                onChange={handleChange}
              />
            </form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            btnType={SECONDARY}
            text="Cancel"
            onClick={handleDismiss}
            disabled={isLoading}
            id="cancel-image-upload-button"
          />
          <Button
            btnType={PRIMARY}
            text={tooManyFilesError ? tooManyFilesText : submitButtonText}
            onClick={handleSubmit}
            type="submit"
            disabled={
              !files?.length || isLoading || showError || tooManyFilesError
            }
            id="submit-image-upload-button"
          />
        </ModalFooter>
      </div>
    </Modal>
  );
};
