// Turning off type-checks for UserActivityProvider because of TypeError due to a change made to React that has not been fixed in UI library yet
// see https://github.com/aws/amazon-chime-sdk-component-library-react/issues/786 for issue updates

import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  UserActivityProvider,
  useAudioVideo,
} from "amazon-chime-sdk-component-library-react";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useDataMessages } from "../providers/dataMessages";
import { useDataEvents } from "../providers/dataEvents";
import { useCaptions } from "../providers/dataCaptions";
import { EventPayload, EventTypes } from "../providers/types";
import useMeetingEndRedirect from "../hooks/useMeetingEndRedirect";
import Canvas from "../components/canvas/canvas";
import { StorageKeys, useLocalStorage } from "../hooks/useLocalStorage";
import { adminRoutes, replayAllowedMeetings, routes } from "../constants";
import {
  allowReplay,
  consoleNonProd,
  findNameFromParams,
  populateMeetingIdFromURL,
} from "../utils/utilityBeltUtils";
import { Videos } from "../components/videos/videos";
import { useMeetingWithRoster } from "../hooks/useMeetingWithRoster";
import { Chat } from "../components/chat";
import { GET_MEETING } from "../graphql/queries";
import {
  Attendee as AttendeeType,
  Board,
  CreateAttendeeInput,
  FeatureFlagType,
  GenerateVttStatus,
  Meeting as MeetingType,
  Mutation,
  ProcessRecordingStatus,
  Query,
  QueryGetMeetingByTitleArgs,
} from "../types";
import Spinner from "../components/spinner";
import { useJoinMeeting } from "../hooks/useJoinMeeting";
import { DataEmotesProvider } from "../providers/dataEmotes";
import { useScreenContext } from "../contexts/screenContext";
import { useAppContext } from "../contexts/appContext";
import { ActionType, CanvasMode, MeetingRole } from "../contexts/types";
import classnames from "classnames";
import { isMobile, isTablet } from "react-device-detect";
import { RecordingIndicator } from "../components/toolbars/controls/recordingIndicator";
import { useFeatureContext } from "../contexts/featureContext";
import { Lobby } from "../components/lobby";
import { BEGIN_RECORD_MEETING, CREATE_ATTENDEE } from "../graphql/mutations";
import { Replay } from "../components/replay/replay";
import { useError } from "../contexts/errorContext";
import { getAttendeeDeviceDate } from "../utils/deviceUtils";
import { useAuthContext } from "../contexts/authContext";
import config from "../utils/config";
import { PopUp } from "../components/popUps/popUp";
import { Modal } from "../components/modal/modal";

// Component for the /meeting route
export const Meeting: React.FC = () => {
  const meetingManager = useMeetingWithRoster();
  const { getLocalStorage, setLocalStorage } = useLocalStorage();
  const { handleJoinMeeting } = useJoinMeeting();
  const { messages, sendMessage, messageCount } = useDataMessages();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const meetingId = populateMeetingIdFromURL(path);
  const {
    dbId,
    simpleView,
    isInstructorOrModerator,
    showChat,
    isDemo,
    setFirstName,
    setLastName,
    meetingMode,
    meetingRole,
    state,
    dispatch,
    meetingHasChat,
  } = useAppContext();
  const { useMobileTools } = useScreenContext();
  const { sendEvent, events } = useDataEvents();
  const { captions, currentPartial } = useCaptions();
  const audioVideo = useAudioVideo();
  const { isAuthenticated } = useAuthContext();
  const initMute = useRef(false);
  const [showInstructors, setShowInstructors] = useState(true);
  const [showRecordingErrorModal, setShowRecordingErrorModal] = useState(false);
  const [recordingSecondAttempt, setRecordingSecondAttempt] = useState(false);
  const [courseName, setCourseName] = useState<string>();
  const [meetingTime, setMeetingTime] = useState<number>();
  const [getMeetingByTitle, setGetMeetingByTitle] =
    useState<MeetingType>(undefined);
  const useReplay = useRef<boolean>(false);
  const { showError } = useError();
  const { featureFlags } = useFeatureContext();
  const lobbyFeature = featureFlags.has(FeatureFlagType.LOBBY);
  const replayFeatureActive = featureFlags.has(FeatureFlagType.REPLAY);
  const replayDemoActive = featureFlags.has(FeatureFlagType.REPLAY_DEMO);
  const userParamsFeature = featureFlags.has(FeatureFlagType.USER_PARAMS);

  const toggleInstructors = () => setShowInstructors(!showInstructors);

  // Get attendee and meeting info from location state or local storage
  const Attendee: AttendeeType = location.state
    ? location.state["attendee"]
    : getLocalStorage(StorageKeys.attendee);

  // change the meeting into a state that can be updated when recording and lobby is updated
  const [meeting, setMeeting] = useState<MeetingType>(
    location.state
      ? location.state["meeting"]
      : getLocalStorage(StorageKeys.meeting)
  );
  // intialize lobby from the meeting's lobby active state
  const [lobbyActive, setLobbyActive] = useState(
    lobbyFeature && meeting?.lobbyActive ? true : false
  );
  const [meetingIsRecording, setMeetingIsRecording] = useState(
    meeting?.currentlyRecording
  );

  /** Query that runs on load to grab meeting details, checks if it should load
   * the lobby, and checks it should load the replay */
  const { loading, data, refetch } = useQuery(GET_MEETING, {
    fetchPolicy: "no-cache",
    variables: {
      title: meetingId,
      replayMeetingPipeline: true,
    } as QueryGetMeetingByTitleArgs,
    onCompleted(data: Pick<Query, "getMeetingByTitle">) {
      setLobbyActive(lobbyFeature && data?.getMeetingByTitle?.lobbyActive);
      setGetMeetingByTitle(data?.getMeetingByTitle);
      const mediaPipeline = data?.getMeetingByTitle.mediaPipelines[0];
      dispatch &&
        dispatch({
          type: ActionType.UPDATE_MUTE_LOCK,
          payload: {
            muteLock: data?.getMeetingByTitle.muteLock,
          },
        });
      dispatch &&
        dispatch({
          type: ActionType.UPDATE_VIDEO_OFF_LOCK,
          payload: {
            videoOffLock: data?.getMeetingByTitle.videoOffLock,
          },
        });
      // Check to see if the meeting ID is in the allow list or if the user is authenticated
      const allowMeetingReplay =
        allowReplay(meetingId, replayAllowedMeetings) || isAuthenticated;

      // Whether or not the meeting can be viewed with replay feature flag off
      const allowReplayFeatureInactive =
        !replayFeatureActive && allowMeetingReplay;

      // Check to see if the captions have been successfully generated in the prod env
      const captionsSuccess =
        config.env.toLowerCase() === "production"
          ? mediaPipeline?.generateCaptionsStatus === GenerateVttStatus.SUCCESS
          : true;
      // Check to see if the events have been successfully generated
      const eventsSuccess =
        mediaPipeline?.generateEventsStatus === GenerateVttStatus.SUCCESS;
      // Check to see if the recording has successfully been generated
      const recordingSuccess =
        mediaPipeline?.processRecordingStatus ===
        ProcessRecordingStatus.SUCCESS;
      // Whether or not the replay has the required elements to be viewed
      const hasRequiredElements =
        captionsSuccess && eventsSuccess && recordingSuccess;

      // Check if the meeting is the replay demo
      const isReplayDemo = meetingId === adminRoutes.replayDemo;

      // Replay meeting
      if (
        // Replay feature flag is on OR this meeting can be viewed with the flag off...
        ((replayFeatureActive || allowReplayFeatureInactive) &&
          // this meeting has a media pipeline returned from the server AND...
          mediaPipeline &&
          // the replay has the required elements (video, events, and captions) AND...
          hasRequiredElements &&
          // the meeting is not the replay demo meeting... OR
          !isReplayDemo) ||
        // The replay demo feature flag is on AND the meeting is the replay demo meeting...
        (replayDemoActive && isReplayDemo)
      ) {
        if (mediaPipeline.replayRecording) {
          useReplay.current = true;
        }
      }
      // Otherwise assume it is a live meeting and set boards from current db state
      else {
        dispatch({
          type: ActionType.HANDLE_GET_PRESENTATION,
          payload: {
            slides: data?.getMeetingByTitle?.boards,
            currentBoard: state.currentBoard
              ? state.currentBoard
              : data?.getMeetingByTitle?.boards[0],
            muteLock: data?.getMeetingByTitle?.muteLock,
            videoOffLock: data?.getMeetingByTitle?.videoOffLock,
          },
        });
      }

      if (
        data?.getMeetingByTitle?.lobbyActive === true &&
        meetingRole === MeetingRole.Audience &&
        lobbyFeature
      ) {
        setShowCanvasAndVideos(false);
        setCourseName(data?.getMeetingByTitle?.course?.name);
        setMeetingTime(data?.getMeetingByTitle?.meetingTime);
      } else {
        setShowCanvasAndVideos(true);
      }
    },
    onError(error) {
      consoleNonProd("Error fetching meeting", dbId, error);
      showError({
        error: "Something went wrong when fetching the meeting.",
      });
    },
  });

  let secondRecordingModalText =
    "Something is still wrong you'll need to reach out to the DEV team";
  let recordingModalBodyText =
    "We were unable to start the recording for this meeting, please try again.";
  //Begin Record Meeting if we need to manually try to kick off recording again
  const [begingMeetingRecordingMutation, mutationResult] = useMutation(
    BEGIN_RECORD_MEETING,
    {
      onCompleted(data: Pick<Mutation, "beginRecordMeeting">) {
        consoleNonProd(data?.beginRecordMeeting?.success);
        setMeetingIsRecording(data?.beginRecordMeeting?.success);
        if (data?.beginRecordMeeting?.success) {
          secondRecordingModalText = "Recording Successfully started";
        }
      },
      onError(error) {
        setRecordingSecondAttempt(true);
        recordingModalBodyText = secondRecordingModalText;
      },
    }
  );

  /** Reset canvas state on unmount */
  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.SET_INIT_STATE,
      });
    };
  }, []);

  useEffect(() => {
    if (featureFlags) {
      setLobbyActive(lobbyFeature && meeting?.lobbyActive);
    }
  }, [lobbyFeature, featureFlags, meeting?.lobbyActive]);

  const storedMeetingMode = location.state
    ? location.state["meetingMode"]
    : getLocalStorage(StorageKeys.meetingMode);

  const [showCanvasAndVideos, setShowCanvasAndVideos] = useState(
    meetingRole === (MeetingRole.Presenter || MeetingRole.Moderator)
  );

  const exploreMode = getLocalStorage(StorageKeys.exploreMode);

  let recordMeetingStatus;
  const storedRecordStatus = getLocalStorage(StorageKeys.recordMeetingStatus);
  if (storedRecordStatus) recordMeetingStatus = storedRecordStatus;
  if (!recordMeetingStatus && location.state)
    recordMeetingStatus = location.state["recordMeetingStatus"];

  const initVersionNumber = location.state
    ? location.state["initVersionNumber"]
    : getLocalStorage(StorageKeys.initVersionNumber);

  /** Handler for refetching boards and sending refetch board event */
  const handleRefetchBoards = () => {
    refetch({
      title: meetingId,
    });
    sendEvent(
      {
        type: EventTypes.REFETCH_BOARDS,
        leader: isInstructorOrModerator,
      },
      state
    );
  };

  const setMeetingFromRecording = (newMeetingInfo) => {
    setMeeting(newMeetingInfo);
    const newState = { ...location.state };
    newState["meeting"] = newMeetingInfo;
    //set the location state to the new meeting values for recording and lobby
    navigate(location.pathname, { replace: true, state: newState });
    setLocalStorage(StorageKeys.meeting, newMeetingInfo);
    setLobbyActive(false);
    setMeetingIsRecording(newMeetingInfo.currentlyRecording);
    // if you are a moderator check to make double check the meeting successfully started recording
    // if it didn't open up the second try again modal
    if (
      meetingRole === MeetingRole.Moderator &&
      newMeetingInfo.currentlyRecording === false
    ) {
      setShowRecordingErrorModal(true);
    }
    sendEvent(
      {
        type: EventTypes.CLOSE_LOBBY,
        leader: isInstructorOrModerator,
        meetingLobbyActive: false,
        meetingCurrentlyRecording: newMeetingInfo.currentlyRecording,
        muteLock: newMeetingInfo?.muteLock,
        videoOffLock: newMeetingInfo?.videoOffLock,
      },
      state
    );
  };

  useEffect(() => {
    if (events?.length > 0) {
      const eventObj = events[events.length - 1];
      if (!eventObj.isSelf && eventObj.event) {
        const event: EventPayload = JSON.parse(eventObj.event);
        // close lobby event is in the meeting because the canvas component isn't available for students
        if (event.leader && event.type === EventTypes.CLOSE_LOBBY) {
          setLobbyActive(event.meetingLobbyActive);
          setMeetingIsRecording(event.meetingCurrentlyRecording);
        }
        if (
          event.leader &&
          event.currentSlideNumber !== state.currentBoard?.sortOrder &&
          state?.slides
        ) {
          dispatch({
            type: ActionType.UPDATE_CURRENT_BOARD,
            payload: {
              currentBoard: state?.slides[event?.currentSlideNumber - 1],
            },
          });
        }
      }
    }
  }, [events, dbId, state.currentBoard, dispatch, state.slides]);

  /** Handles refetching the boards when a refetch event occurs */
  useEffect(() => {
    if (events?.length > 0) {
      const eventObj = events[events.length - 1];
      if (!eventObj.isSelf && eventObj.event) {
        const event: EventPayload = JSON.parse(eventObj.event);
        if (event.leader && event.type === EventTypes.REFETCH_BOARDS) {
          refetch({ title: meetingId });
        }
      }
    }
  }, [refetch, events, meetingId]);

  const [
    createAttendeeMutation,
    { loading: createAttendeeLoading, error: createAttendeeError },
  ] = useMutation(CREATE_ATTENDEE, {
    async onCompleted({ createAttendee }: Pick<Mutation, "createAttendee">) {
      if (createAttendee) {
        const attendeeData = { ...createAttendee };
        delete attendeeData["meeting"]; // Remove meeting data from the attendee data
        await handleJoinMeeting(
          attendeeData,
          createAttendee.meeting,
          meetingMode
        ).then(() =>
          // Navigate to the meeting after the meeting manager starts
          // use location state here to pass meeting and attendee info to prevent issues w/ local storage clearing
          navigate(`/${routes.meeting}/${createAttendee.meeting.title}`, {
            state: {
              attendee: attendeeData,
              meeting: createAttendee.meeting,
              meetingMode,
            },
          })
        );
      }
    },
    onError(error) {
      const paramError = error?.graphQLErrors[0]?.extensions?.errors[0]?.message
        ? error.graphQLErrors[0].extensions.errors[0].message
        : undefined;
      navigate(`/${routes.join}/${meetingId}`, {
        state: { ...location.state, paramError },
      });
    },
  });

  const paramNavigate = (firstParam, secondParam) => {
    // first check if the class is a replay
    // why not use "useReplay.current"? It was returning a false false...
    if (getMeetingByTitle?.mediaPipelines[0]?.replayRecording) {
      navigate(`/${routes.watch}/${meetingId}`);
    } else {
      const input: CreateAttendeeInput = {
        firstName: firstParam,
        lastName: secondParam,
        title: meetingId,
        deviceInfo: getAttendeeDeviceDate(),
      };
      createAttendeeMutation({ variables: { input } });
    }
  };

  // Try to rejoin meeting if meeting session does not exist
  useEffect(() => {
    const rejoinMeeting = async () => {
      if (
        Attendee &&
        meeting &&
        meeting?.title?.toLocaleLowerCase() === meetingId?.toLocaleLowerCase()
      ) {
        dispatch({
          type: ActionType.UPDATE_EXPLORE_MODE,
          payload: { exploreMode },
        });
        await handleJoinMeeting(Attendee, meeting, storedMeetingMode);
      } else {
        // Navigate to the meeting using the username params and create attendee
        const currentUrl = new URL(document.URL);
        const urlParams = new URLSearchParams(currentUrl.searchParams);
        const fullUserName = findNameFromParams(urlParams);
        if (
          fullUserName.firstName &&
          fullUserName.lastName &&
          !createAttendeeLoading &&
          !createAttendeeError &&
          userParamsFeature &&
          !loading
        ) {
          setFirstName(fullUserName.firstName);
          setLastName(fullUserName.lastName);
          paramNavigate(fullUserName.firstName, fullUserName.lastName);
        } else if (!loading && useReplay.current === false) {
          // Redirect to join page if there is no meeting or attendee data in local storage
          navigate(`/${routes.join}/${meetingId}`);
        }
      }
    };

    if (!meetingManager.meetingSession) rejoinMeeting();
  }, [
    Attendee,
    meeting,
    storedMeetingMode,
    exploreMode,
    dispatch,
    handleJoinMeeting,
    meetingId,
    navigate,
    meetingManager.meetingSession,
    loading,
  ]);

  // Set mic to muted when page renders and audio video is initialized
  useEffect(() => {
    if (audioVideo && !initMute.current) {
      audioVideo?.realtimeMuteLocalAudio();
      initMute.current = true;
    }
  }, [audioVideo]);

  // handles the logic for showing the canvas to students and activates their audio
  useEffect(() => {
    if (lobbyFeature) {
      if (meetingRole === MeetingRole.Audience && !lobbyActive) {
        setShowCanvasAndVideos(true);
      }
      if (meetingRole === MeetingRole.Audience && lobbyActive) {
        setShowCanvasAndVideos(false);
      }
    }
    // if it's a demo force the lobby closed and run as normal
    if (isDemo) {
      setLobbyActive(false);
      setShowCanvasAndVideos(true);
    }
  }, [lobbyActive, meetingRole, lobbyFeature, meeting, isDemo]);

  useMeetingEndRedirect();
  return (
    // @ts-ignore
    <UserActivityProvider>
      <DataEmotesProvider>
        {loading ? (
          <Spinner />
        ) : (
          meetingManager.meetingSession && (
            <div
              className={classnames("meeting", {
                "meeting__mobile-full-min-height": isMobile || isTablet,
              })}
            >
              {/* Video will render on mobile in the mobileTools component */}
              {!simpleView && showCanvasAndVideos && (
                <Videos
                  showInstructors={showInstructors}
                  toggleInstructors={toggleInstructors}
                  events={events}
                />
              )}
              {/* Show this Chat for Audience Members when showChat is true, simpleView is false, ONLY on desktop/isLarge screens
                On smaller screens, the chat will display in the mobileTools */}
              {meetingRole === MeetingRole.Audience &&
                showChat &&
                meetingHasChat &&
                !simpleView &&
                !useMobileTools && (
                  <div className="meeting-chat-container">
                    <Chat
                      height="40vh"
                      messages={messages}
                      sendMessage={sendMessage}
                      messageCount={messageCount}
                    />
                  </div>
                )}
              {meetingRole === MeetingRole.Audience &&
                lobbyActive &&
                lobbyFeature && (
                  <Lobby
                    courseName={courseName}
                    meetingTime={meetingTime}
                    messages={messages}
                    sendMessage={sendMessage}
                    messageCount={messageCount}
                  />
                )}
              {showRecordingErrorModal && (
                <Modal
                  dismissible={true}
                  display={showRecordingErrorModal}
                  onDismiss={() => {
                    setShowRecordingErrorModal(false);
                  }}
                  noBackground={true}
                >
                  <div className="__modal-content">
                    <>
                      {mutationResult?.loading && <Spinner />}
                      <PopUp
                        popUpHeader={
                          showRecordingErrorModal && !recordingSecondAttempt
                            ? "Oh no!"
                            : ""
                        }
                        userMessage={recordingModalBodyText}
                        buttonText1="Dismiss"
                        buttonText2={recordingSecondAttempt ? "" : "Try again"}
                        buttonType1="secondary"
                        buttonType2="primary"
                        onClick1={() => {
                          setShowRecordingErrorModal(false);
                        }}
                        onClick2={() => {
                          begingMeetingRecordingMutation({
                            variables: { id: state.currentBoard?.meetingId },
                          });
                          setRecordingSecondAttempt(true);
                        }}
                      />
                    </>
                  </div>
                </Modal>
              )}
              {showCanvasAndVideos && (
                <Fragment>
                  {/* Meeting recording indicator for instructors and moderators */}
                  {(meetingRole === MeetingRole.Presenter ||
                    meetingRole === MeetingRole.Moderator) && (
                    <RecordingIndicator
                      recordingActive={meetingIsRecording}
                      lobbyActive={lobbyActive}
                      handleMeetingActivated={(e) => setMeetingFromRecording(e)}
                    />
                  )}
                  <Canvas
                    captions={captions}
                    currentPartial={currentPartial}
                    handleRefetchBoards={handleRefetchBoards}
                    sendEvent={sendEvent}
                    sendMessage={sendMessage}
                    events={events}
                    mode={CanvasMode.PRESENTATION}
                    showInstructors={showInstructors}
                    messages={messages}
                    messageCount={messageCount}
                    setShowInstructors={setShowInstructors}
                    toggleInstructors={toggleInstructors}
                    setShowChapterList={() => {
                      consoleNonProd("meeting show chapter");
                    }}
                  />
                </Fragment>
              )}
            </div>
          )
        )}
        {useReplay.current === true && (
          <Replay
            replayMeeting={data?.getMeetingByTitle}
            sendMessage={sendMessage}
          />
        )}
      </DataEmotesProvider>
    </UserActivityProvider>
  );
};
