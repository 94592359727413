import { gql } from "@apollo/client";
import {
  BOARD_FIELDS,
  ATTENDEE_FIELDS,
  BOARD_IMAGE_FIELDS,
  PANORAMA_FIELDS,
  BOARD_VIDEO_FIELDS,
  BOARD_THREEDEE_FIELDS,
  BASE_MEETING_FIELDS,
  IMAGE_UPLOAD_FIELDS,
} from "./fragments";

export const GET_MEETING_BOARDS = gql`
  ${BOARD_FIELDS}
  ${BOARD_IMAGE_FIELDS}
  ${PANORAMA_FIELDS}
  ${BOARD_VIDEO_FIELDS}
  ${BOARD_THREEDEE_FIELDS}
  ${IMAGE_UPLOAD_FIELDS}
  query GetMeetingBoards($meetingId: Int!) {
    getMeetingBoards(
      meetingId: $meetingId
      sortBy: { field: "sortOrder", order: ASC }
    ) {
      ...BoardFields
      meetingId
      boardImages {
        ...BoardImageFields
        imageUpload {
          ...ImageUploadFields
        }
      }
      boardPanorama {
        panorama {
          ...PanoramaFields
        }
      }
      boardVideo {
        ...BoardVideoFields
      }
      boardThreedee {
        ...BoardThreedeeFields
      }
    }
  }
`;

export const GET_MEETING_BOARDS_BY_MEETING_TITLE = gql`
  ${BOARD_FIELDS}
  ${BOARD_IMAGE_FIELDS}
  ${PANORAMA_FIELDS}
  ${BOARD_VIDEO_FIELDS}
  ${BOARD_THREEDEE_FIELDS}
  ${IMAGE_UPLOAD_FIELDS}
  query getMeetingBoardsByMeetingTitle($meetingTitle: String!) {
    getMeetingBoardsByMeetingTitle(
      meetingTitle: $meetingTitle
      sortBy: { field: "sortOrder", order: ASC }
    ) {
      ...BoardFields
      meetingId
      boardImages {
        ...BoardImageFields
        imageUpload {
          ...ImageUploadFields
        }
      }
      boardPanorama {
        panorama {
          ...PanoramaFields
        }
      }
      boardVideo {
        ...BoardVideoFields
      }
      boardThreedee {
        ...BoardThreedeeFields
      }
    }
  }
`;

export const GET_COURSES_BY_MEETING_DATES = gql`
  query getCoursesByMeetingDates(
    $todayInput: DateCourseInput
    $currentInput: DateCourseInput
    $futureInput: DateCourseInput
    $pastInput: DateCourseInput
  ) {
    todaysCourses: getCoursesByMeetingDates(input: $todayInput) {
      id
      name
      slug
      meetings {
        meetingTime
        title
        courseId
      }
    }
    currentCourses: getCoursesByMeetingDates(input: $currentInput) {
      id
      name
      slug
      meetings {
        meetingTime
        title
        courseId
      }
    }
    futureCourses: getCoursesByMeetingDates(input: $futureInput) {
      id
      name
      slug
      meetings {
        meetingTime
        title
        courseId
      }
    }
    pastCourses: getCoursesByMeetingDates(input: $pastInput) {
      id
      name
      slug
      meetings {
        meetingTime
        title
        courseId
      }
    }
    getAllCourses {
      name
      slug
      meetings {
        meetingTime
        title
      }
    }
  }
`;

export const GET_ALL_COURSES = gql`
  query getAllCourses {
    getAllCourses {
      name
      slug
      meetings {
        meetingTime
        title
      }
    }
  }
`;

export const GET_ALL_ACTIVE_FEATURE_FLAGS = gql`
  query getAllActiveFeatureFlags {
    getAllActiveFeatureFlags {
      name
    }
  }
`;

export const GET_AUTHENTICATED_USER = gql`
  query getAuthenticatedUser {
    getAuthenticatedUser {
      firstName
      lastName
      message
      isAuthenticated
    }
  }
`;

export const SEARCH_ART_OBJECTS = gql`
  query SearchArtObjects($paginate: Paginate, $term: String) {
    searchArtObjects(paginate: $paginate, term: $term) {
      title
      id
      invno
      people
      medium
      imageSecret
      imageOriginalSecret
      ensembleIndex
    }
  }
`;

export const GET_PANORAMAS = gql`
  query GetAllPanoramas {
    getAllPanoramas {
      id
      name
      sourceUrl
      collection
    }
  }
`;

export const SEARCH_IMAGE_UPLOADS = gql`
  ${IMAGE_UPLOAD_FIELDS}
  query SearchArtObjects(
    $paginate: Paginate
    $term: String
    $permissions: [String]
  ) {
    searchImageUploads(
      paginate: $paginate
      term: $term
      permissions: $permissions
    ) {
      ...ImageUploadFields
    }
  }
`;

export const GET_ATTENDEE = gql`
  ${ATTENDEE_FIELDS}
  query ($externalId: String!) {
    getAttendeeByExternalId(externalId: $externalId) {
      ...AttendeeFields
    }
  }
`;

export const GET_COURSE_BY_SLUG = gql`
  query ($slug: String!) {
    getCourseBySlug(slug: $slug) {
      name
      slug
      id
      meetings {
        meetingTime
        title
        id
        courseId
        instructorAV
        studentAV
        chat
        muteLock
        videoOffLock
        mediaPipelines {
          id
          createdAt
          awsConcatPipelineId
          replayRecording
          processRecordingStatus
          generateChatStatus
          generateEventsStatus
          generateChaptersStatus
          generateCaptionsStatus
          attendeeLogStatus
          chatLogStatus
        }
      }
    }
  }
`;

export const GET_MEETING_BY_TITLE = gql`
  ${BASE_MEETING_FIELDS}
  ${BOARD_FIELDS}
  ${BOARD_IMAGE_FIELDS}
  ${PANORAMA_FIELDS}
  ${BOARD_VIDEO_FIELDS}
  ${BOARD_THREEDEE_FIELDS}
  ${IMAGE_UPLOAD_FIELDS}
  query ($title: String!) {
    getMeetingByTitle(title: $title) {
      ...BaseMeetingFields
      boards {
        ...BoardFields
        meetingId
        boardImages {
          ...BoardImageFields
          imageUpload {
            ...ImageUploadFields
          }
        }
        boardPanorama {
          panorama {
            ...PanoramaFields
          }
        }
        boardVideo {
          ...BoardVideoFields
        }
        boardThreedee {
          ...BoardThreedeeFields
        }
      }
    }
  }
`;

export const GET_MEETING = gql`
  ${BOARD_FIELDS}
  ${BOARD_IMAGE_FIELDS}
  ${PANORAMA_FIELDS}
  ${BOARD_VIDEO_FIELDS}
  ${BOARD_THREEDEE_FIELDS}
  ${IMAGE_UPLOAD_FIELDS}
  query ($title: String!, $replayMeetingPipeline: Boolean) {
    getMeetingByTitle(
      title: $title
      replayMeetingPipeline: $replayMeetingPipeline
    ) {
      id
      lobbyActive
      currentlyRecording
      meetingTime
      muteLock
      videoOffLock
      course {
        name
      }
      boards {
        ...BoardFields
        meetingId
        boardImages {
          ...BoardImageFields
          imageUpload {
            ...ImageUploadFields
          }
        }
        boardPanorama {
          panorama {
            ...PanoramaFields
          }
        }
        boardVideo {
          ...BoardVideoFields
        }
        boardThreedee {
          ...BoardThreedeeFields
        }
      }
      mediaPipelines: MediaPipelines {
        awsConcatPipelineId
        replayRecording
        generateCaptionsStatus
        generateChatStatus
        generateEventsStatus
        generateChaptersStatus
        processRecordingStatus
      }
    }
  }
`;

export const GET_REPLAY_DEMO = gql`
  ${BASE_MEETING_FIELDS}
  ${BOARD_FIELDS}
  ${BOARD_IMAGE_FIELDS}
  ${PANORAMA_FIELDS}
  ${BOARD_VIDEO_FIELDS}
  ${BOARD_THREEDEE_FIELDS}
  ${IMAGE_UPLOAD_FIELDS}
  query ($title: String!) {
    getMeetingByTitle(title: $title) {
      ...BaseMeetingFields
      boards {
        ...BoardFields
        meetingId
        boardImages {
          ...BoardImageFields
          imageUpload {
            ...ImageUploadFields
          }
        }
        boardPanorama {
          panorama {
            ...PanoramaFields
          }
        }
        boardVideo {
          ...BoardVideoFields
        }
        boardThreedee {
          ...BoardThreedeeFields
        }
      }
      mediaPipelines {
        id
        awsConcatPipelineId
        awsPipelineId
        folderName
      }
    }
  }
`;

export const GET_REPLAY = gql`
  query getReplayMeeting($title: String!) {
    getReplayMeeting(title: $title) {
      id
      instructorAV
      studentAV
      chat
      mediaPipelines: MediaPipelines {
        awsConcatPipelineId
        replayRecording
        generateCaptionsStatus
        generateChatStatus
        generateEventsStatus
        generateChaptersStatus
        processRecordingStatus
        videoLength
      }
    }
  }
`;

export const ON_APP_LOAD = gql`
  query getAuthenticatedUser {
    getAuthenticatedUser {
      firstName
      lastName
      message
      isAuthenticated
    }
    getAllActiveFeatureFlags {
      name
    }
  }
`;
