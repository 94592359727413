import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  BoardType,
  CreateBoardImage,
  CreateBoardPanorama,
  CreateBoardVideoInput,
  Mutation,
  PanoramaCollection,
} from "../../types";
import { getTileSourceURL } from "../../utils/artObjectUtils";
import {
  CREATE_BOARD_IMAGES,
  CREATE_BOARD_PANORAMA,
  CREATE_BOARD_VIDEO,
} from "../../graphql/mutations";
import { PanoramaImage } from "./addPanoramaImage";
import AddVideo from "./addVideo";
import { AddImageUpload } from "./addImageUpload";
import { AddEnsembleImage } from "./addEnsembleImage";
import { getEnsembleImgIIF } from "../../utils/ensembleUtil";
import { AddArtObject } from "./addArtObject";
import { TabList } from "./tabList";
import { useAppContext } from "../../contexts/appContext";
import { Modal } from "../modal/modal";
import { PopUp } from "../popUps/popUp";
import { consoleNonProd } from "../../utils/utilityBeltUtils";
import { Button, SECONDARY, SMALL } from "../buttons/button";
import { imageUploadGuideLink } from "../../constants";

type AddContentProps = {
  onAddObjectCompleted: (currentVersion: number) => void;
  toggleAddContent: () => void;
};

/** Component for the Add Content Modal
 * @param {AddContentProps} props
 * @prop {() => void} onBoardImageCompleted - Function to be called after the mutation to add an image to the board has been completed successfully
 */
export const AddContent: React.FC<AddContentProps> = ({
  onAddObjectCompleted,
  toggleAddContent,
}) => {
  const contentTabs = {
    AllArtPieces: { name: "Barnes Collection Works", id: "collection-objects" },
    EnsembleImages: { name: "Barnes Ensemble Images", id: "ensemble-images" },
    PanoramaImages: {
      name: "Barnes Gallery 360 Images",
      id: "panorama-images",
    },
    Videos: { name: "Video Content", id: "video" },
    ImageUpload: { name: "Uploaded Images", id: "uploaded-images" },
    PanoramaUpload: { name: "Uploaded Panoramas", id: "uploaded-panoramas" },
  };

  enum TabType {
    BARNES_COLLECTION,
    BARNES_ENSEMBLE,
    BARNES_PANORAMA,
    VIDEO,
    IMAGE_UPLOAD,
    PANO_UPLOAD,
  }

  const { state } = useAppContext();
  const [activeTab, setActiveTab] = useState<string>(
    contentTabs.AllArtPieces.name
  );
  const [areYouSurePopup, setAreYouSurePopup] = useState(false);
  const [currentTabType, setCurrentTabType] = useState<TabType>(undefined);
  const [currentBoardType, setCurrentBoardType] = useState<BoardType>(
    state.currentBoard?.type
  );
  // this is just a container to hold the data passed up from the child components
  const [areYouSureData, setAreYouSureData] = useState(undefined);

  const [createBoardImagesMutation] = useMutation(CREATE_BOARD_IMAGES, {
    onCompleted(data: Pick<Mutation, "createBoardImages">) {
      const currentVersion = data.createBoardImages.currentVersion;
      onAddObjectCompleted(currentVersion);
    },
    onError(error) {
      consoleNonProd(
        "Error adding image(s) to board",
        state.currentBoard.id,
        error
      );
    },
  });

  const [createBoardPanoramaMutation] = useMutation(CREATE_BOARD_PANORAMA, {
    onCompleted(data: Pick<Mutation, "createBoardPanorama">) {
      const currentVersion = data.createBoardPanorama.currentVersion;
      onAddObjectCompleted(currentVersion);
    },
    onError(error) {
      consoleNonProd(
        "Error adding panorama to board",
        state.currentBoard.id,
        error
      );
    },
  });

  const [createBoardVideoMutation] = useMutation(CREATE_BOARD_VIDEO, {
    onCompleted(data: Pick<Mutation, "createBoardVideo">) {
      const currentVersion = data.createBoardVideo.currentVersion;
      onAddObjectCompleted(currentVersion);
    },
    onError(error) {
      consoleNonProd(
        "Error adding video to board",
        state.currentBoard.id,
        error
      );
    },
  });

  // Submit handler for Barnes Collection Images
  const handleSubmit = (selectedInvnos: string[]) => {
    if (currentBoardType !== BoardType.IIIF) {
      setChangeBoardData(
        selectedInvnos,
        TabType.BARNES_COLLECTION,
        BoardType.IIIF
      );
    } else {
      toggleAddContent();
      const input: CreateBoardImage[] = selectedInvnos.map((invno) => {
        return {
          tileSource: getTileSourceURL(invno),
          boardId: state.currentBoard.id,
        };
      });
      createBoardImagesMutation({ variables: { input } });
    }
  };

  // Submit handler for panorama images
  const handlePanoramaSubmit = (id: number): void => {
    if (currentBoardType !== BoardType.PANORAMA) {
      setChangeBoardData(id, TabType.BARNES_PANORAMA, BoardType.PANORAMA);
    } else {
      toggleAddContent();
      const input: CreateBoardPanorama = {
        boardId: state.currentBoard.id,
        panoramaId: id,
      };
      createBoardPanoramaMutation({ variables: { input } });
    }
  };

  // Submit handler for Videos
  const handleVideoSubmit = (url: string): void => {
    if (currentBoardType !== BoardType.VIDEO) {
      setChangeBoardData(url, TabType.VIDEO, BoardType.VIDEO);
    } else {
      toggleAddContent();
      // start and end not implemented at this point
      const input: CreateBoardVideoInput = {
        boardId: state.currentBoard.id,
        sourceUrl: url,
      };
      createBoardVideoMutation({ variables: { input } });
    }
  };

  // Submit handler for uploaded images
  const handleImageSubmit = (imageUploadIds: number[]) => {
    if (currentBoardType !== BoardType.IIIF) {
      setChangeBoardData(imageUploadIds, TabType.IMAGE_UPLOAD, BoardType.IIIF);
    } else {
      toggleAddContent();
      const input: CreateBoardImage[] = imageUploadIds.map((imageUploadId) => {
        return { imageUploadId, boardId: state.currentBoard.id };
      });
      createBoardImagesMutation({ variables: { input } });
    }
  };

  // Submit handler for Barnes Collection Images
  const handleEnsembleSubmit = (ensembleIndexes: string[]) => {
    if (currentBoardType !== BoardType.IIIF) {
      setChangeBoardData(
        ensembleIndexes,
        TabType.BARNES_ENSEMBLE,
        BoardType.IIIF
      );
    } else {
      toggleAddContent();
      const input: CreateBoardImage[] = ensembleIndexes.map((index) => {
        return {
          tileSource: getEnsembleImgIIF(index),
          boardId: state.currentBoard.id,
        };
      });
      createBoardImagesMutation({ variables: { input } });
    }
  };

  const handleChangeBoardType = (inputType: TabType) => {
    switch (inputType) {
      case TabType.BARNES_COLLECTION:
        handleSubmit(areYouSureData);
        break;
      case TabType.BARNES_ENSEMBLE:
        handleEnsembleSubmit(areYouSureData);
        break;
      case TabType.BARNES_PANORAMA:
        handlePanoramaSubmit(areYouSureData);
      case TabType.VIDEO:
        handleVideoSubmit(areYouSureData);
      case TabType.IMAGE_UPLOAD:
        handleImageSubmit(areYouSureData);
      default:
        break;
    }
  };

  /**
   * @param {any} data - the data coming back from the child components to be based to the backend
   * @param {tabType} tabType - the current selected tab in the add modal so we know what backend call to call
   * @param {BoardType} boardType - the boardType that the board will be changing into
   */
  const setChangeBoardData = (data, tabType, boardType) => {
    setAreYouSurePopup(true);
    setAreYouSureData(data);
    setCurrentTabType(tabType);
    setCurrentBoardType(boardType);
  };

  /**
   *  resets the are you sure popup, clears the tabType, and resets the boardType
   *
   */
  const cancelBoardTypeChange = () => {
    setAreYouSurePopup(false);
    setCurrentTabType(undefined);
    setCurrentBoardType(state.currentBoard?.type);
  };

  return (
    <div
      role="dialog"
      aria-labelledby="addContentTitle"
      aria-describedby="addContentDesc"
      className="add-content"
    >
      <div className="add-content__left">
        <TabList
          tabs={Object.values(contentTabs)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <Button
          btnType={SECONDARY}
          disabled={false}
          size={SMALL}
          text="Image upload guide"
          onClick={() => window.open(imageUploadGuideLink, "_blank")}
          icon="help"
          id="image-upload-guide"
        />
      </div>

      {/* All Art Pieces Tab */}
      {activeTab === contentTabs.AllArtPieces.name && (
        <AddArtObject
          title={contentTabs.AllArtPieces.name}
          handleSubmit={handleSubmit}
        />
      )}

      {/* Barnes Galleries/Rooms Panorama Images */}
      {activeTab === contentTabs.PanoramaImages.name && (
        <PanoramaImage
          title={contentTabs.PanoramaImages.name}
          handleSubmit={handlePanoramaSubmit}
          collection={PanoramaCollection.BARNES}
        />
      )}
      {/* Videos */}
      {activeTab === contentTabs.Videos.name && (
        <AddVideo
          disabled={false}
          leader={false}
          videoSaveClick={(url: string) => {
            handleVideoSubmit(url);
          }}
        />
      )}
      {activeTab === contentTabs.ImageUpload.name && (
        <AddImageUpload
          title={contentTabs.ImageUpload.name}
          handleSubmit={handleImageSubmit}
        />
      )}
      {/* Non-Barnes uploaded Panorama Images */}
      {activeTab === contentTabs.PanoramaUpload.name && (
        <PanoramaImage
          title={contentTabs.PanoramaUpload.name}
          handleSubmit={handlePanoramaSubmit}
          collection={PanoramaCollection.UPLOADS}
        />
      )}
      {activeTab === contentTabs.EnsembleImages.name && (
        <AddEnsembleImage
          title={contentTabs.EnsembleImages.name}
          handleSubmit={handleEnsembleSubmit}
        />
      )}
      <Modal
        dismissible={true}
        display={areYouSurePopup}
        onDismiss={() => {
          cancelBoardTypeChange();
        }}
      >
        <div className="__modal-content">
          <PopUp
            popUpHeader="You are about to change the type of board"
            userMessage="Changing board types will remove any board content and replace it with your new selection. Are you sure you would like to do that?"
            buttonText1="Cancel"
            buttonText2="Yes"
            buttonType1="secondary"
            buttonType2="primary"
            onClick1={() => {
              cancelBoardTypeChange();
            }}
            onClick2={() => {
              setAreYouSurePopup(false);
              handleChangeBoardType(currentTabType);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
